import animateScrollTo from 'animated-scroll-to';

export const scrollToElement = (id, offset = 0) => {
  const element = document.getElementById(id);
  if (!element) return false;
  const target = element.offsetTop - 50;
  window.requestAnimationFrame(() => {
    animateScrollTo(target + offset, { speed: 5000 });
  });
  return true;
};

export default scrollToElement;
