import React from 'react';
import PodSignup from '../podSignup';
import EmailSignup from '../emailSignup';
import heroStyles from './hero.module.css';

export default function Hero() {
  return (
    <div className={heroStyles.heroBackground}>
      <div className={heroStyles.subscribeWrapper}>
        <div className={heroStyles.subscribeBlock}>
          <EmailSignup />
          <div>
            <h2 className={heroStyles.hero}>
              <a href="https://www.buymeacoffee.com/AvidResearch" target="_blank" rel="noreferrer">Buy Avid Research a Coffee!</a>
            </h2>
            <h2 className={heroStyles.hero}>
              Follow us on your favourite podcast platform
            </h2>
            <PodSignup />
          </div>
        </div>
      </div>
    </div>
  );
}
