import React from 'react';
import podcastCardCollapsedStyles from './podcastCardCollapsed.module.css';

export default function PodcastCardCollapsed(props) {
  const {
    index,
    name,
    icon,
    id,
    onClick,
  } = props;
  return (
    <a
      href={`/?episodeId=${id}`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={podcastCardCollapsedStyles.card}
      id={`episode${id}`}
    >
      <div className={podcastCardCollapsedStyles.content}>
        <div className={podcastCardCollapsedStyles.cardContent}>
          <div className={podcastCardCollapsedStyles.header}>
            <h2 className={podcastCardCollapsedStyles.heading}>
              {`#${index}`}
              {' '}
              {name}
            </h2>
            <p className={podcastCardCollapsedStyles.icon}>{icon}</p>
          </div>
        </div>
      </div>
    </a>
  );
}
