import React from 'react';
import { FaSpotify, FaApple } from 'react-icons/fa';
import { SiGooglepodcasts } from 'react-icons/si';
import StitcherLogo from '../../static/stitcherLogo.svg';

export default [
  {
    provider: 'Apple',
    url: 'https://podcasts.apple.com/au/podcast/avid-research/id1527745431',
    logo: <FaApple />,
  },
  {
    provider: 'Spotify',
    url: 'https://open.spotify.com/show/333usLV7ZebJ51OAcQUxqe',
    logo: <FaSpotify />,
  },
  {
    provider: 'Stitcher',
    url: 'https://www.stitcher.com/s?fid=564064&refid=stpr',
    logo: <img src={StitcherLogo} alt="Stitcher Radio Logo" />,
  },
  {
    provider: 'Google',
    url: 'https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuYXZpZHJlc2VhcmNoLmNvbS5hdS9yc3MueG1s',
    logo: <SiGooglepodcasts />,
  },
];
