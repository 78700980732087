import React from 'react';
import podcastProviderList from '../../data/podcastProviders';
import podSignupStyles from './podSignup.module.css';

export default function PodSignup() {
  return (
    <div className={podSignupStyles.container}>
      {
      podcastProviderList.map((provider) => (
        <a
          key={`provider_${provider.provider}`}
          href={provider.url}
          target="_blank"
          rel="noreferrer"
          className={podSignupStyles.logo}
        >
          {provider.logo}
        </a>
      ))
    }
    </div>
  );
}
