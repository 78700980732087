export default [
  {
    href: 'https://avidresearch.com.au/',
    label: 'Latest Episode',
  },
  {
    href: 'https://www.buymeacoffee.com/AvidResearch',
    label: 'Buy Avid Research a Coffee!',
  },
  {
    href: 'https://www.amtrav.dev/',
    label: 'For blogs about Avid Research',
  },
  {
    href: 'https://www.mysciencemay.com/',
    label: 'For everything #MyScienceMay',
  },
  {
    href: 'https://avidresearch.com.au/mappingourway/',
    label: 'Mapping Our Way exhibition at Melbourne City Library',
  },
  {
    href: 'https://www.youtube.com/channel/UCdPa9O8UXh9c1dG8FFVWfDQ',
    label: 'YouTube',
  },
  {
    href: 'https://open.spotify.com/show/333usLV7ZebJ51OAcQUxqe',
    label: 'Listen on Spotify',
  },
  {
    href: 'https://podcasts.apple.com/au/podcast/avid-research/id1527745431',
    label: 'Listen on iTunes',
  },
];
