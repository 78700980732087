export default [
  {
    person: 'Jielai',
    career: 'Astronomer',
    map: '/careerMaps/JL_Career.JPG',
    episode: '/?episodeId=011',
    alt: 'image is a career map of an astronomer',
  },
  {
    person: 'David',
    career: 'Web Development Team Lead',
    map: '/careerMaps/David.JPG',
    episode: '/',
    alt: 'image is a career map of a web developer',
  },
  {
    person: 'Niamh',
    career: 'Heart Health Researcher',
    map: '/careerMaps/Niamh.JPG',
    episode: '/?episodeId=025',
    alt: 'image is a career map of a heart health researcher and podcaster',
  },
  {
    person: 'Ciaran',
    career: 'Stingray Researcher',
    map: '/careerMaps/Ciaran.JPG',
    episode: '/?episodeId=021',
    alt: 'image is a career map of a stingray researcher',
  },
  {
    person: 'Jo',
    career: 'Civil Engineer',
    map: '/careerMaps/Jo.JPG',
    episode: '/?episodeId=022',
    alt: 'image is a career map of a civil engineer',
  },
];
