export default [
  // {
  //   title: 'Career Maps',
  //   link: '/mappingourway',
  // },
  {
    title: 'About',
    link: '/about',
  },
  {
    title: 'Blogs',
    link: 'https://www.amtrav.dev/',
  },
  // {
  //   title: 'Book List',
  //   link: '/bookList',
  // },
  {
    title: 'Support us',
    link: 'https://www.buymeacoffee.com/AvidResearch',
  },
  // {
  //   link: 'https://www.mysciencemay.com/',
  //   title: '#MyScienceMay',
  // },
  {
    title: 'Shop',
    link: 'https://www.redbubble.com/people/amaltr42/shop?artistUserName=amaltr42&collections=1803464&iaCode=all-departments&sortOrder=relevant',
  },
];
