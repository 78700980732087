export default [
  {
    bookTitle: 'Project Hail Mary',
    author: 'Andy Weir',
    bookLink: 'https://www.goodreads.com/book/show/54493401-project-hail-mary',
    theme: 'Science Fiction - Space Drama',
    recommenderName: 'Allison Hornery',
    recommenderTwitter: 'https://twitter.com/allisonhornery',
  },
  {
    bookTitle: 'The Art of Statistics: How to Learn from Data',
    author: 'David Spiegelhalter',
    bookLink: 'https://www.goodreads.com/book/show/43722897-the-art-of-statistics',
    theme: 'Nonfiction - Statistics',
    recommenderName: 'Nicole White',
    recommenderTwitter: 'https://twitter.com/nicolem_white',
  },
  {
    bookTitle: 'Raising Heretics: Teaching Kids to Change the World',
    author: 'Linda McIver',
    bookLink: 'https://www.goodreads.com/book/show/58690124-raising-heretics',
    theme: 'Nonfiction - Change the world',
    recommenderName: 'Linda McIver',
    recommenderTwitter: 'https://twitter.com/lindamciver',
  },
  {
    bookTitle: 'Automating Inequality',
    author: 'Virginia Eubanks',
    bookLink: 'https://us.macmillan.com/books/9781250074317',
    theme: 'Nonfiction - Artificial Intelligence and Ethics',
    recommenderName: 'Linda McIver',
    recommenderTwitter: 'https://twitter.com/lindamciver',
  },
  {
    bookTitle: 'Made by Humans',
    author: 'Ellen Broad',
    bookLink: 'https://www.mup.com.au/books/made-by-humans-paperback-softback',
    theme: 'Nonfiction - Data and Ethics',
    recommenderName: 'Linda McIver',
    recommenderTwitter: 'https://twitter.com/lindamciver',
  },
  {
    bookTitle: 'Outlawed',
    author: 'Anna North',
    bookLink: 'https://www.goodreads.com/book/show/50997696-outlawed',
    theme: 'Historial Fiction - Queer - Infertility',
    recommenderName: 'Emmalee Ford',
    recommenderTwitter: 'https://twitter.com/EmmaOolemma',
  },
  {
    bookTitle: 'Written in Bone: Hidden Stories in What We Leave Behind',
    author: 'Sue Black',
    bookLink: 'https://www.goodreads.com/book/show/49098237-written-in-bone',
    theme: 'Nonfiction - True Crime',
    recommenderName: 'Tori Berezowski',
    recommenderTwitter: 'https://twitter.com/BerezowskiTori',
  },
  {
    bookTitle: 'Reasonable Doubt',
    author: 'Xanthe Mallett',
    bookLink: 'https://www.goodreads.com/book/show/53332356-reasonable-doubt',
    theme: 'Nonfiction - True Crime - wrongful conviction',
    recommenderName: 'Tori Berezowski',
    recommenderTwitter: 'https://twitter.com/BerezowskiTori',
  },
  {
    bookTitle: 'Jurassic Park',
    author: 'Michael Crichton',
    bookLink: 'https://www.goodreads.com/book/show/40604658-jurassic-park',
    theme: 'Dinosaur Drama',
    recommenderName: 'Erin Hahn',
    recommenderTwitter: 'https://twitter.com/erin_e_hahn',
  },
  {
    bookTitle: 'A Sting in the Tale: My Adventures with Bumblebees',
    author: 'Dave Goulson',
    bookLink: 'https://www.goodreads.com/book/show/18465526-a-sting-in-the-tale',
    theme: 'Bees - Conservation',
    recommenderName: 'Kit Prendergast',
    recommenderTwitter: 'https://twitter.com/BeeBabette',
  },
  {
    bookTitle: 'Animals Make Us Human',
    author: 'Leah Kaminsky and Meg Keneally',
    bookLink: 'https://www.goodreads.com/book/show/54398181-animals-make-us-human',
    theme: 'Nonfiction - Animals - Connection with Nature',
    recommenderName: 'Kelly Williams',
    recommenderTwitter: 'https://twitter.com/Williams_Kelly2',
  },
  {
    bookTitle: 'Atlas of AI: Power, Politics, and the Planetary Costs of Artificial Intelligence',
    author: 'Kate Crawford',
    bookLink: 'https://www.goodreads.com/en/book/show/50131136-atlas-of-ai',
    theme: 'AI - The Environment',
    recommenderName: 'Ashley Anderson',
    recommenderTwitter: 'https://twitter.com/ash__and',
  },
  {
    bookTitle: 'Weapons of Math Destruction: How Big Data Increases Inequality and Threatens Democracy',
    author: 'Cathy O\'Neil',
    bookLink: 'https://www.goodreads.com/book/show/28186015-weapons-of-math-destruction',
    theme: 'Data - Ethics',
    recommenderName: 'Ashley Anderson',
    recommenderTwitter: 'https://twitter.com/ash__and',
  },
  {
    bookTitle: 'The Immortal Life of Henrietta Lacks',
    author: 'Rebecca Skloot',
    bookLink: 'https://www.goodreads.com/book/show/6493208-the-immortal-life-of-henrietta-lacks',
    theme: 'Medicine - Ethics',
    recommenderName: 'Ashley Anderson',
    recommenderTwitter: 'https://twitter.com/ash__and',
  },
  {
    bookTitle: 'Being Mortal: Medicine and What Matters in the End',
    author: 'Atul Gawande',
    bookLink: 'https://www.goodreads.com/book/show/20696006-being-mortal',
    theme: 'Medicine - Mortality',
    recommenderName: 'Isabella Bower',
    recommenderTwitter: 'https://twitter.com/bella_s_bower',
  },
  {
    bookTitle: 'Gathering Moss: The Natural and Cultural History of Mosses',
    author: 'Robin Wall Kimmerer',
    bookLink: 'https://www.goodreads.com/book/show/17465709-braiding-sweetgrass',
    theme: 'History - Plants - Culture',
    recommenderName: 'Sharon Robinson',
    recommenderTwitter: 'https://twitter.com/Antarcticmoss',
  },
  {
    bookTitle: 'Braiding Sweetgrass: Indigenous Wisdom, Scientific Knowledge, and the Teachings of Plants',
    author: 'Robin Wall Kimmerer',
    bookLink: 'https://www.goodreads.com/book/show/87040.Gathering_Moss',
    theme: 'Plants - Moss - Culture',
    recommenderName: 'Sharon Robinson',
    recommenderTwitter: 'https://twitter.com/Antarcticmoss',
  },
  {
    bookTitle: 'Dr Rips Essential Beach Book Everything You Need to Know about Surf Sand & Rips',
    author: 'Brander Rob',
    bookLink: 'https://www.bookshop.unsw.edu.au/details.cgi?ITEMNO=9781742230979',
    theme: 'Beach - Nonfiction - Coasts',
    recommenderName: 'Hannah Power',
    recommenderTwitter: 'https://twitter.com/DrHannahPower',
  },
  {
    bookTitle: 'Written in Stone: reading the rocks of the Great Ocean Road',
    author: 'Philomena Manifold',
    bookLink: 'https://www.writteninstone.net.au/#thebook',
    theme: 'Geology - Great Ocean Road - History',
    recommenderName: 'Melanie Finch',
    recommenderTwitter: 'https://twitter.com/melaniefinch_',
  },
  {
    bookTitle: 'Timefulness: How Thinking Like a Geologist Can Help Save the World',
    author: 'Marcia Bjornerud',
    bookLink: 'https://www.goodreads.com/book/show/39204074-timefulness',
    theme: 'Geology - Change the world',
    recommenderName: 'Melanie Finch',
    recommenderTwitter: 'https://twitter.com/melaniefinch_',
  },
  {
    bookTitle: 'Notes from Deep Time: The Hidden Stories of the Earth Beneath Our Feet',
    author: 'Helen Gordon',
    bookLink: 'https://www.goodreads.com/en/book/show/50353808-notes-from-deep-time',
    theme: 'Geology - History',
    recommenderName: 'Melanie Finch',
    recommenderTwitter: 'https://twitter.com/melaniefinch_',
  },
  {
    bookTitle: 'Snowball Earth: The Story of the Great Global Catastrophe That Spawned Life as We Know It',
    author: 'Gabrielle Walker',
    bookLink: 'https://www.goodreads.com/en/book/show/1020063.Snowball_Earth',
    theme: 'Geology - History - Adventure',
    recommenderName: 'Caroline Tiddy',
    recommenderTwitter: 'https://twitter.com/CarolineTiddy',
  },
  {
    bookTitle: 'Rock Star: The Story Of Reg Sprigg An Outback Legend',
    author: 'Kristin Weidenbach',
    bookLink: 'https://www.goodreads.com/book/show/6586171-rock-star',
    theme: 'Australian - Outback - Geology',
    recommenderName: 'Caroline Tiddy',
    recommenderTwitter: 'https://twitter.com/CarolineTiddy',
  },
];
