import React, { useState } from 'react';
import episodeData from '../../data/episodeList';
import PodcastCard from '../podcastCard';
import PodcastCardCollapsed from '../podcastCardCollapsed';
import podcastListingStyles from './podcastListing.module.css';
import withLocation from '../../utils/withLocation';

const PodcastListing = (props) => {
  const {
    search,
    navigate,
  } = props;
  const { episodeId } = search;
  // filters out episodes that haven't been released yet
  // In this case episodes who have future dates
  const filteredEpisodeData = episodeData.filter((episode) => {
    const today = new Date();
    const episodeDate = new Date(episode.date);
    return today >= episodeDate;
  });
  // organises episodes from least recent to most recent
  const sortedEpisodeData = filteredEpisodeData.sort((a, b) => {
    if (a.index > b.index) return -1;
    if (b.index > a.index) return 1;
    return 0;
  });
  // setting the state for the selected episode card
  const [selected, setSelected] = useState(episodeId);

  return (
    <div className={podcastListingStyles.listing}>
      {
        sortedEpisodeData.map((episode, index) => (
          selected === episode.id || (!selected && index === 0)
            ? (
              <PodcastCard
                key={`episode_${episode.id}`}
                id={episode.id}
                index={episode.index}
                date={episode.date}
                name={episode.name}
                icon={episode.icon}
                image={episode.image}
                synopsis={episode.synopsis}
                path={episode.path}
                alt={episode.alt}
                isFirst={index === 0}
                linkList={episode.linkList}
                guest={episode.guest}
                isExpanded
                isSelected={selected}
              />
            )
            : (
              <PodcastCardCollapsed
                key={`episode_${episode.id}`}
                name={episode.name}
                index={episode.index}
                icon={episode.icon}
                id={episode.id}
                onClick={() => {
                  setSelected(episode.id);
                  navigate(`/?episodeId=${episode.id}`);
                }}
              />
            )
        ))
      }
    </div>
  );
};

export default withLocation(PodcastListing);
