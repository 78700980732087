import React from 'react';
import Nav from '../nav';
import headerStyles from './header.module.css';

export default function Header(props) {
  const {
    hasNav,
  } = props;
  return (
    <div className={headerStyles.headerContents}>
      <a
        href="/"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/logo.PNG" alt="Avid Research Logo - Australian STEM Stories" className={headerStyles.image} />
      </a>
      {hasNav && <Nav />}
    </div>
  );
}
