import React from 'react';
import aboutStyles from './about.module.css';

export default function About() {
  return (
    <div className={aboutStyles.containerWrapper}>
      <div className={aboutStyles.container}>
        <h1 className={aboutStyles.header}>
          The Who, What, Where, When, Why and How of Avid Research.
        </h1>
        <h3 className={aboutStyles.subHeader}>
          Who is behind Avid Research?
        </h3>
        <p className={aboutStyles.body}>
          Avid Research is a podcast created by me, Amelia, I’m a web developer who’s
          been a STEM Educator, Park Ranger and long time student.
          I’ve studied Geography, Geographic Information Systems, Polar Science and Teaching,
          accumulating 4 degrees in the process.
          <br />
          Since I was a little kid I’ve loved Science and asking questions.
          I was fascinated with things like why there are bubbles when pancakes cook,
          and how aeroplanes work and I was encouraged to ask “Why?” and to work out the answers.
          <br />
          I genuinely believe interest in Science Technology Engineering and Maths (STEM)
          is something universal, and that questioning and curiosity should be encouraged!
          <br />
          The guests I have on the show are people from all kinds of backgrounds, with all
          sorts of knowledge and experiences, they’re all experts in their own ways.
          Avid Research is my opportunity to share cool stories, fun findings about the
          world and for me to talk to cool people doing awesome stuff!
          <br />
          It’s a great fun project to work on, thank you for joining in!
        </p>
        <h3 className={aboutStyles.subHeader}>
          What is this whole thing?
        </h3>
        <p className={aboutStyles.body}>
          Avid Research is a passion project, meaning there’s no sponsorship or payment
          for doing it.
          It’s a Podcast, with various social medias, powered by Earl Grey Tea and Banana Bread.
          Basically, I ask questions, interview people and tell
          stories that broadly link to STEM topics.
          It’s a window into different STEM careers,
          and an opportunity for people to learn more about
          the behind the scenes of the world we live in.
        </p>
        <h3 className={aboutStyles.subHeader}>
          Where is this happening?
        </h3>
        <p className={aboutStyles.body}>
          Based in Victoria, Australia the podcast and website is created on the lands of the
          Boon Wurrung and Woiwurrung (Wurundjeri) peoples of the Kulin Nation.
          Interviewees come from all over, however I try and focus on interviewing people
          who may otherwise not have access to a platform like a podcast to tell their story.
          A large driver of this Podcast was to hear more
          Australian accents sharing STEM experiences.
        </p>
        <h3 className={aboutStyles.subHeader}>
          When can we expect to hear this awesome content?
        </h3>
        <p className={aboutStyles.body}>
          I aim to release a podcast weekly on Friday. At the moment (Sept 2020)
          it’s an experiment to see if that’s sustainable. Each Podcast takes about
          6 hours to create, which is more than I had anticipated!
          But weekly seems to be working for listeners,
          it’s a nice cadence to be working at.
        </p>
        <h3 className={aboutStyles.subHeader}>
          Why does Avid Research Exist?
        </h3>
        <p className={aboutStyles.body}>
          After about 8 years of wanting to create a Podcast,
          to tell stories and share the awesome stuff that can be found in
          the world of STEM, the right combination of things came together
          in 2020 to make it happen.
          <br />
          I wanted to tell Australian STEM stories,
          hear more Australian accents on Podcasts.
          <br />
          Over the years of thinking
          about it I also developed the desire to understand and share what different
          STEM careers were like. As a student in highschool we were shown some
          windows into STEM jobs, but really we didn’t have any idea of what these people did.
          <br />
          I’m doing this to help people see what they can do with a STEM career,
          what the challenges are, what the opportunities are and how experimental
          you can be while working out what you want to do with your life.
          <br />
          Undertaking this during the COVID-19 Pandemic turned out to have an
          additional benefit, the opportunity to call all sorts of cool people,
          ask them questions! The “why?” is now largely because it’s fun,
          because I’m getting some really good content.
          <br />
          People have amazing nuggets of wisdom, and I want to share them!
        </p>
        <h3 className={aboutStyles.subHeader}>
          How is this podcast, website and promo materials created?
        </h3>
        <p className={aboutStyles.body}>
          The technical bit.
          <br />
          The podcasts themselves are recorded on a Blue Snowball
          microphone given to me for Christmas.
          <br />
          When I’m recording by myself it’s done straight into Audacity,
          when recording an interview I use Zencastr, which at about 10 interviews
          so far has worked without a hitch.
          <br />
          I don’t script anything, sometimes I plan what I’m going to say,
          but more often than not I just have a general idea and chat away.
          <br />
          My interviewees get the questions before the interview,
          which has been a great way to get good information from people!
          <br />
          All the interviewees so far have been personal and professional contacts
          and I’m now getting contacts from the people I’ve interviewed,
          which is a great way of growing the podcast.
          <br />
          After recording everything is edited in Audacity.
          I could speed up the whole process and not edit. However it’s
          really important to me to have high quality sound, and not to drive the
          listener up the wall with all the little clicks,
          ticks and umms that we make while talking.
          <br />
          Sometimes things are edited for clarity.
          The editing is by far the slowest part of the process,
          but it’s worth it for this project.
          <br />
          Podcasts are then uploaded to the website here where they can
          be accessed directly by listeners, and also by various podcast
          distributors such as Spotify and Apple.
          <br />
          This website has been created using the basic Gatsby (React) starter,
          hosted on AWS Amplify.
          <br />
          The key plugins for this website are:
          <br />
          React-h5-audio-player has been added to allow
          people to listen to the podcast on the  website.
          <br />
          Gatsby-plugin-podcast-rss-feed allows the website
          to generate a RSS feed which can be picked up by Apple
          and Spotify and means I can host the website.
          <br />
          Gatsby-plugin-mailchimp gives me the ability to link in with my MailChimp mailing list.
          <br />
          As it stands this is a very simple website, but it’s working very effectively!
          <br />
          All marketing collateral is created by me, usually while listening to an audiobook,
          on my little iPad with the iPencil in Procreate.
          <br />
          Once I worked out how to create animations in Procreate
          I’ve been able to use iMovie to combine podcast snippets
          with mini animations of my stick figures.
          <br />
          <br />
          If there’s something else that you’re curious about with regards to the
          mechanics of the podcast, drop me a line.
        </p>
      </div>
    </div>
  );
}
