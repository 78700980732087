import React, { useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import podcastCardStyles from './podcastCard.module.css';
import animateScrollTo from '../../utils/scrollTo';

export default function PodcastCard(props) {
  const {
    id,
    index,
    // date,
    name,
    icon,
    image,
    synopsis,
    path,
    alt,
    linkList,
    isFirst,
    isSelected,
    guest,
  } = props;

  useEffect(() => {
    if (isSelected) animateScrollTo(`episode${id}`);
  });

  return (
    <div
      className={podcastCardStyles.card}
      id={`episode${id}`}
    >
      {
        isFirst && (
          <div className={podcastCardStyles.latestEpisodeContainer}>
            <div className={podcastCardStyles.latestEpisode}>Latest Episode!</div>
          </div>
        )
      }
      <div className={podcastCardStyles.content}>
        <img src={image} alt={alt} className={podcastCardStyles.image} />
        <div className={podcastCardStyles.cardContent}>
          <div className={podcastCardStyles.header}>
            <h2 className={podcastCardStyles.heading}>
              {`#${index}`}
              {' '}
              {name}
            </h2>
            <p className={podcastCardStyles.icon}>{icon}</p>
          </div>
          <p className={podcastCardStyles.synopsis}>{synopsis}</p>
          <div className={podcastCardStyles.linkContainer}>
            {
              linkList.length > 0
              && (
              <div className={podcastCardStyles.links}>
                <h3>Related links</h3>
                <ul>
                  {
                    linkList.map((articleLink) => (
                      <li key={`article_${articleLink.url}`}>
                        <a
                          className={podcastCardStyles.articleLinks}
                          href={articleLink.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {articleLink.title}
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </div>
              )
            }
            {
              guest && (
                <div className={podcastCardStyles.guestBackground}>
                  <div className={podcastCardStyles.guest}>
                    <h3>{`Episode Guest - ${guest.name}`}</h3>
                    <p className={podcastCardStyles.guestInfo}>{guest.description}</p>
                    <a
                      className={podcastCardStyles.guestLink}
                      href={guest.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {guest.linkText}
                    </a>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>

      <div className={podcastCardStyles.playerAlignment}>
        <div className={podcastCardStyles.playerWidth}>
          <AudioPlayer
            className={podcastCardStyles.player}
            src={path}
          />
        </div>
      </div>
    </div>
  );
}
