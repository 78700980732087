import React from 'react';
import booklist from '../../data/bookList';
import booksStyles from './books.module.css';

export default function Maps() {
  return (
    <div className={booksStyles.container}>
      <h1
        className={booksStyles.header}
      >
        Recommended STEM Booklist
      </h1>
      {
      booklist.map((map) => (
        <div
          className={booksStyles.wrapper}
        >
          <h2
            className={booksStyles.title}
          >
            <a
              key={`map_${map.bookTitle}`}
              href={map.bookLink}
              target="_blank"
              rel="noreferrer"
              className={booksStyles.title}
            >
              {`${map.bookTitle} by ${map.author}`}
            </a>
          </h2>
          <p>{map.theme}</p>
          <p>
            Recommended by
            <a
              key={`map_${map.bookTitle}`}
              href={map.recommenderTwitter}
              target="_blank"
              rel="noreferrer"
              className={booksStyles.link}
            >
              {` ${map.recommenderName}`}
            </a>
          </p>
        </div>

      ))
    }
    </div>
  );
}
