import React from 'react';
import {
  FaMapMarkedAlt,
} from 'react-icons/fa';
import exhibitionStyles from './exhibition.module.css';
import EmailSignup from '../emailSignup';
import PodcastCard from '../podcastCard';
import Maps from '../careerMaps';

const podcastCardProps = {
  id: '042',
  index: 42,
  date: '2021-05-12',
  name: 'What\'s the deal with the Career Maps?',
  icon: <FaMapMarkedAlt />,
  image: '/episodeImage/CareerMap.JPG',
  synopsis: 'In this episode I have a bit of a chat about what the career maps are, get a bit excited about somne of my theories about how they could help people beat imposter syndrome and invite you to come see them at an exhibition in Melbourne in July!',
  path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_CareerMaps.mp3',
  alt: 'cartoon of a very vague career map',
  guest: undefined,
  linkList: [
    {
      title: 'Check out some of the career maps',
      url: 'https://avidresearch.com.au/maps/',
    },
    {
      title: 'Head over to the Libraries website',
      url: 'https://www.melbourne.vic.gov.au/community/libraries/whats-on/exhibitions/Pages/exhibitions.aspx',
    },
  ],
};

export default function Exhibition() {
  return (
    <div className={exhibitionStyles.containerWrapper}>
      <EmailSignup heading="Sign up for the latest updates!" />
      <div className={exhibitionStyles.container}>
        <h1 className={exhibitionStyles.header}>
          Mapping Our Way
        </h1>
        <p className={exhibitionStyles.body}>
          Have you ever wondered what the average career looks like?
          <br />
          Have you been curious about how someone went from wanting to be a firefighter
          or politician while at school to being a technician on film sets or an Astronomer?
          <br />
          What about how to navigate times of career uncertainty or change?
          In this exhibition Amelia has mapped the career journey of Science,
          Technology, Engineering and Maths (STEM) professionals. By applying her
          mapping skills to careers she has found a way to visualise the complexity of
          professional lives, and hopes to inspire others to document their own career
          journeys, or dreams, in more creative ways.
          <br />
          Through this adventure, Amelia has found that careers are almost never linear,
          aren’t at all covered by what goes on your CV and certainly isn’t just what you’re
          paid for!
        </p>
        <PodcastCard {...podcastCardProps} />
        <div className={exhibitionStyles.imageWrapper}>
          <img className={exhibitionStyles.image} src="/careerMaps/MappingOurWayLogo.JPG" alt="logo of the mapping my way exhibition" />
          <img className={exhibitionStyles.image} src="/careerMaps/MappingOurWayImage.JPG" alt="Mashup of multiple career maps" />
        </div>
        <h2>
          Follow the exhibition progress on
          <a className={exhibitionStyles.linky} href="https://www.instagram.com/avid_research/?hl=en" rel="noreferrer"> Instagram!</a>
        </h2>
        <Maps />
      </div>
    </div>
  );
}
