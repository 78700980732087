import React from 'react';
import {
  GiSpermWhale,
  GiFishingBoat,
  GiMusicalNotes,
  GiAtom,
  GiTallBridge,
  GiFishhookFork,
  GiFlowerPot,
  GiHeartBeats,
  GiSharkFin,
  GiChicken,
  GiHeartInside,
  GiBrain,
  GiWaveCrest,
  GiBookPile,
  GiWhirlwind,
  GiPolarBear,
  GiNuclear,
  GiSwordwoman,
  GiIdea,
  GiCricket,
  GiBrainStem,
  GiNightSleep,
  GiGooeyMolecule,
  GiDeliveryDrone,
  GiAtSea,
  GiTalk,
  GiShield,
  GiSpade,
  GiWorms,
  GiMedicines,
  GiSpaceShuttle,
  GiForest,
  GiSoundWaves,
  GiClownfish,
  GiSpeedometer,
  GiCloudDownload,
} from 'react-icons/gi';
import {
  FaGlobeAsia,
  FaEarlybirds,
  FaFilm,
  FaHospital,
  FaGamepad,
  FaRegSun,
  FaWater,
  FaQuestion,
  FaCat,
  FaLaptopCode,
  FaSuitcase,
  FaFileMedicalAlt,
  FaThinkPeaks,
  FaBuilding,
  FaFrog,
  FaLaptop,
  FaHammer,
  FaHamburger,
  FaMapMarkedAlt,
  FaMicrophoneAlt,
  FaDna,
  FaWineBottle,
  FaBirthdayCake,
  FaMountain,
  FaPencilRuler,
  FaRegLightbulb,
  FaCodeBranch,
  FaPercent,
  FaLightbulb,
  FaMicroscope,
  FaAtom,
  FaShip,
  FaHeartbeat,
  FaTshirt,
  FaSatelliteDish,
} from 'react-icons/fa';
import { MdPregnantWoman } from 'react-icons/md';
import { GoTelescope } from 'react-icons/go';
import {
  AiOutlineVideoCamera, AiFillCloud, AiFillBug, AiOutlineCompass,
} from 'react-icons/ai';
import { RiMedicineBottleLine } from 'react-icons/ri';

export default [
  {
    id: '001',
    index: 1,
    date: '2020-07-01',
    name: 'Why shouldn\'t you explode a dead whale?',
    icon: <GiSpermWhale />,
    image: '/episodeImage/ExplodingWhale.JPG',
    synopsis: 'For our introductory episode we are bringing you the story of a whale. This whale met its end and then washed up on a beach in Far East Gippsland, Victoria, Australia. Listen to this episode to hear what people thought should be done about this whale, and what happened in the end!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_ExplodingWhales.mp3',
    alt: 'cartoon of a whale being exploded - boom',
    guest: undefined,
    linkList: [
      {
        title: 'An Avid Research extras animation on why exploding dead whales is a bad idea',
        url: 'https://youtu.be/4EpwxWAuOj0',
      },
      {
        title: 'Wiki on exploding whales - many fun facts',
        url: 'https://en.wikipedia.org/wiki/Exploding_whale',
      },
      {
        title: 'Scholarly article on diseases you can catch from marine mammals',
        url: 'https://www.int-res.com/articles/dao_oa/d081p081.pdf',
      },
    ],
  },
  {
    id: '002',
    index: 2,
    date: '2020-07-01',
    name: 'What\'s the difference between Antarctica and the Arctic?',
    icon: <FaGlobeAsia />,
    image: '/episodeImage/ArcticAntarctic.JPG',
    synopsis: 'Have you ever wondered what the difference is between Antarctica and the Arctic? Well, here is the answer from an Australian geographers point of view. Talking about people, geography and biology.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_ArcticAntarctic.mp3',
    alt: 'cartoon of the globe highlighting Antarctica and the Arctic',
    guest: undefined,
    linkList: [
      {
        title: 'NASA article on the differences',
        url: 'https://climate.nasa.gov/blog/2861/arctic-and-antarctic-sea-ice-how-are-they-different/#:~:text=The%20primary%20difference%20between%20the,ice%20and%20surrounded%20by%20land.&text=Antarctica%2C%20on%20the%20other%20hand,ice%20and%20the%20Southern%20Ocean.',
      },
      {
        title: 'TED Talk cute cartoon video on the differences',
        url: 'https://www.ted.com/talks/camille_seaman_the_arctic_vs_the_antarctic/transcript?language=en#t-73276',
      },
    ],
  },
  {
    id: '003',
    index: 3,
    date: '2020-07-21',
    name: 'What\'s it like to be a research diver?',
    icon: <GiFishingBoat />,
    image: '/episodeImage/ResearchDiver.JPG',
    synopsis: 'Have you ever wondered what its like to work on the Great Barrier Reef as a SCUBA diver? Natalie, Master Reef Guide and Research diver joins us for an interview where she shares her journey to arrive at such an awesome job! We\'ll be talking about the challenge of managing Crown of Thorns Seastars, protecting the reef and citizen science!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_NatInterview.mp3',
    alt: 'cartoon of a fishing boat',
    guest: {
      name: 'Natalie Lobartolo',
      link: 'https://www.instagram.com/nattylob/?hl=en',
      linkText: 'Check out her Instagram here for some awesome Reef photos!',
      description: 'Master Reef Guide, Research Diver and Marine Biologist',
    },
    linkList: [
      {
        title: 'The Reef Citizen Science Alliance',
        url: 'https://www.reefcitizenscience.org/?fbclid=IwAR07F0o8acfvxT7WIJ5ZPhFVz23wkCto6YsJQ5CA4FIc6DeMIYb6rITgjkI',
      },
      {
        title: 'Eye on the Reef - Citizen Science',
        url: 'http://www.gbrmpa.gov.au/our-work/eye-on-the-reef',
      },
      {
        title: 'Crown of Thorns - Environmental Pressure',
        url: 'https://www.stateoftheenvironment.des.qld.gov.au/biodiversity/estuarine-and-marine-ecosystems/crown-of-thorns-starfish-pressure-on-the-great-barrier-reef',
      },
      {
        title: 'Vinegar v.s Crown of Thorns',
        url: 'https://blog.csiro.au/vinegar-a-secret-weapon-in-the-fight-against-crown-of-thorns-starfish/  ',
      },
    ],
  },
  {
    id: '004',
    index: 4,
    date: '2020-08-27',
    name: 'Why are there happy fat birds in the Arctic Polar Night?',
    icon: <FaEarlybirds />,
    image: '/episodeImage/FatBird.JPG',
    synopsis: 'Once upon a time, not very long ago, Scientists discovered that there are happy fat birds hanging out in the Arctic Polar Night. This was curious and mysterious because it is dark in the Arctic winter, and the birds should have been starving without being able to hunt. Today we start to answer the question, Why are those birds so happy and fat in the cold dark?',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_FatBirdsArctic.mp3',
    alt: 'cartoon of a happy bird',
    guest: undefined,
    linkList: [
      {
        title: 'An Avid Research extras animation about the happy fat birds',
        url: 'https://youtu.be/i-bFvmU7aA4',
      },
      {
        title: 'The 2014 Mare Incognitum Blog',
        url: 'https://mare-incognitum.no/marinenight2014/',
      },
      {
        title: 'Video of a bird feeding underwater in the dark',
        url: 'https://mare-incognitum.no/marinenight2014/?p=105',
      },
    ],
  },
  {
    id: '005',
    index: 5,
    date: '2020-09-03',
    name: 'What\'s it like to teach kids about making computer games?',
    icon: <FaGamepad />,
    image: '/episodeImage/GameDesignTeach.JPG',
    synopsis: 'Casey has created one of the coolest ever teaching jobs for herself, teaching young people in Ballarat how to design and build their own computer games. Have a listen to hear about the opportunities available in the games industry and the value of learning to code!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Casey.mp3',
    alt: 'cartoon of a teacher holding a game controller teaching game design',
    guest: {
      name: 'Casey Thomas',
      link: 'https://www.linkedin.com/in/casey-thomas-712288117/',
      linkText: 'Check out her LinkedIn here to have a chat!',
      description: 'Program Facilitator at the Ballarat Tech School and CEO of Dark Shadow Studios',
    },
    linkList: [
      {
        title: 'ACMI on how to teach with computer games',
        url: 'https://www.acmi.net.au/game-lessons/',
      },
      {
        title: 'Awesome work being done by Girl Geek Academy',
        url: 'https://girlgeekacademy.com/',
      },
      {
        title: 'Tertiary courses in game design at AIE',
        url: 'https://aie.edu.au/',
      },
    ],
  },
  {
    id: '006',
    index: 6,
    date: '2020-09-10',
    name: 'What\'s is like to work in Emergency Medicine?',
    icon: <FaHospital />,
    image: '/episodeImage/ICUDoc.JPG',
    synopsis: 'Have you ever wondered what it\'s like working in Intensive Care as a doctor? Or perhaps how you can combine medical knowledge with another passion, like Engineering? On the show today, we are interviewing Sian, a doctor and student who shares with us some amazing knowledge about working in Medicine and some of the alternative pathways available.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_ICUDoc.mp3',
    alt: 'cartoon of a doctor holding a clipboard saying ICU',
    guest: {
      name: 'Sian Rouse-Watson',
      link: '',
      linkText: '',
      description: 'ICU Doctor and Student of Engineering',
    },
    linkList: [
      {
        title: 'All about ECMO, one of the cool technologies found in ICU',
        url: 'https://en.wikipedia.org/wiki/Extracorporeal_membrane_oxygenation#:~:text=Extracorporeal%20membrane%20oxygenation%20(ECMO)%2C,or%20perfusion%20to%20sustain%20life.',
      },
    ],
  },
  {
    id: '007',
    index: 7,
    date: '2020-09-17',
    name: 'What\'s is like to be a LiDAR Technician on Films?',
    icon: <FaFilm />,
    image: '/episodeImage/LidarTech.JPG',
    synopsis: 'Have you ever wondered what its like to work on film sets with cutting edge technology? Meet Rick, a LiDAR Technician who works on films all over the world. His job is to collect highly accurate data about sets so they can be turned into the films we know and love. Tune in to hear his story!',
    path: '/podcastEpisodes/2020_Rick_Lidar.mp3',
    alt: 'cartoon of a person with a scanning box',
    guest: {
      name: 'Rick Harris',
      link: '',
      linkText: '',
      description: 'LiDAR Technician and Geographer',
    },
    linkList: [
      {
        title: 'Tested - How LiDAR is used for VFX',
        url: 'https://www.tested.com/art/538855-how-lidar-used-visual-effects/',
      },
      {
        title: 'A very fun little video on how LiDAR works',
        url: 'https://www.youtube.com/watch?v=EYbhNSUnIdU&vl=en',
      },
    ],
  },
  {
    id: '008',
    index: 8,
    date: '2020-09-24',
    name: 'What\'s is like to be a Conservation Project Officer?',
    icon: <FaRegSun />,
    image: '/episodeImage/ProjectOfficer.JPG',
    synopsis: 'Western Queensland is an arid place with broad horizons, graziers and tenacious wildlife. For Ben, a Conservation Project Officer, it\'s a fantastic opportunity to work in a field he loves and to make positive changes for the environment! Tune in to hear about conservation, the Australian Outback and the life-changing impact of science camps like NYSF!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Ben_Conservation.mp3',
    alt: 'cartoon of a person with a camera in the desert with the sun',
    guest: {
      name: 'Ben Galea',
      link: 'https://www.nysf.edu.au/',
      linkText: 'Head over to the NYSF website for all things STEM-MY at school!',
      description: 'Drylands Conservation Project Officer',
    },
    linkList: [
      {
        title: 'Have a look at Desert Channels Queensland for more information about the Lake Eyre Basin',
        url: 'https://dcq.org.au/',
      },
      {
        title: 'A wonderful clip from the BOM about the flooding of Lake Eyre',
        url: 'https://www.youtube.com/watch?v=yEXqgt-DWRk',
      },
    ],
  },
  {
    id: '009',
    index: 9,
    date: '2020-10-01',
    name: 'What\'s is like to be a Marine Technician?',
    icon: <FaWater />,
    image: '/episodeImage/MarTech.JPG',
    synopsis: 'Colleen is a Marine Technician working on Research Vessels. She shares some very awesome stories with us about mapping underwater volcanoes and people going a bit weird after being at sea too long. We talk about having patience with yourself, giving yourself space to learn, and the value of open data.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Colleen_MarineTech.mp3',
    alt: 'cartoon of a person standing on a boat at sea',
    guest: {
      name: 'Colleen Peters',
      link: 'https://www.abc.net.au/radio-australia/programs/pacificbeat/tongas-newest-island-could-provide-otherworldly/7391840',
      linkText: 'For more information about mapping Hunga Tonga Hunga Ha\'apai head here',
      description: 'Marine Technician',
    },
    linkList: [
      {
        title: 'How do we map the sea floor? An Avid Research extras Animation',
        url: 'https://youtu.be/RSx5zmKwZUU',
      },
      {
        title: 'One fifth of the Ocean floor is mapped, check out the story here',
        url: 'https://www.bbc.com/news/science-environment-53119686',
      },
      {
        title: 'International effort to map the seafloor by 2030',
        url: 'https://seabed2030.gebco.net/',
      },
    ],
  },
  {
    id: '010',
    index: 10,
    date: '2020-10-08',
    name: 'What is Avid Research all about?',
    icon: <FaQuestion />,
    image: '/episodeImage/WWWWWH.JPG',
    synopsis: 'In this episode I go through the who, what, where, when, why and how of Avid Research. This includes why I\'ve created the podcast, and now little videos, the joy of asking questions and how the name Avid Research came to be. I\'d love to hear your thoughts about it!',
    path: '/podcastEpisodes/2020_WWWWWH.mp3',
    alt: 'cartoon of questions, Who, What, Where, When, Why and How',
    guest: {
      name: 'Amelia',
      link: 'https://avidresearchepisodes.s3.amazonaws.com/2020_WWWWWH.mp3',
      linkText: 'To investigate my CV, check out my LinkedIn',
      description: 'Question Asker, Founder of Avid Research',
    },
    linkList: [
      {
        title: 'Have a look at some awesome Instagram promo work here',
        url: 'https://www.instagram.com/avid_research/?hl=en',
      },
      {
        title: 'If you\'re more a Facebook person, check us out on Facebook',
        url: 'https://www.facebook.com/AvidResearch',
      },
      {
        title: 'Keen to learn more about Gatsby?',
        url: 'https://www.gatsbyjs.com/',
      },
    ],
  },
  {
    id: '011',
    index: 11,
    date: '2020-10-15',
    name: 'What\'s it like to be an Astronomer?',
    icon: <GoTelescope />,
    image: '/episodeImage/Astronomer.JPG',
    synopsis: 'Have you ever wondered what it\'s like to be an Astronomer? Wonder no more! In this episode I interview Jielai, a passionate Astronomer, Physicist and coder. We talk about the myth of the lone genius in science, the secret explosiveness of the universe and co-directing a summer camp for aspiring astronomers in Africa!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_jielai_astronomer.mp3',
    alt: 'cartoon of a person standing next to a telescope, holding a laptop and thinking of galaxies',
    guest: {
      name: 'Dr Jielai Zhang',
      link: 'https://jielaizhang.github.io/',
      linkText: 'To see more of Jielai\'s work have a look at her GitHub',
      description: 'Time Dimensional Astronomer',
    },
    linkList: [
      {
        title: 'Interested in Work Experience at Swinburne?',
        url: 'https://www.swinburne.edu.au/collaboration-partnerships/school-programs/work-experience/',
      },
      {
        title: 'Online lectures for using Python for Astronomy',
        url: 'http://growth.caltech.edu/growth-astro-school-2020-resources.html',
      },
      {
        title: 'Tutorial\'s for using Python for Astronomy',
        url: 'http://learn.astropy.org/',
      },
      {
        title: 'Astronomy Citizen Science',
        url: 'https://www.zooniverse.org/projects/zookeeper/galaxy-zoo/',
      },
      {
        title: 'African Summer School in Astronomy',
        url: 'https://www.paseaafrica.org/',
      },
    ],
  },
  {
    id: '012',
    index: 12,
    date: '2020-10-22',
    name: 'What\'s it like to be a STEM inspired Musician?',
    icon: <GiMusicalNotes />,
    image: '/episodeImage/SteveMusician.JPG',
    synopsis: 'In this episode I interview Steve a Tinkerer, Musician and Music Teacher. We talk about making sure you don\'t get bored with your work, building your own looping pedal and some of the quirks that come with playing with sound in enclosed spaces.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Steve_Music.mp3',
    alt: 'cartoon of a person holding a guitar with music notes in the air',
    guest: {
      name: 'Steve Warner',
      link: 'https://www.facebook.com/pg/stevewarneraustralia/',
      linkText: 'Check out Steve\'s Facebook Page',
      description: 'Professional Musician and music teacher',
    },
    linkList: [
      {
        title: 'Avid Research Cartoon about Sound bouncing around a room',
        url: 'https://youtu.be/N-M4Qr3z7JA',
      },
      {
        title: 'For more about Maths in Music, have a look here!',
        url: 'http://www.ams.org/publicoutreach/math-and-music',
      },
      {
        title: 'Instructable on building a looping pedal',
        url: 'https://www.instructables.com/DIY-Chewie-Monsta-Ed-Sheeran-Loop-Pedal/',
      },
      {
        title: 'Build your own podcasting booth',
        url: 'https://rachelcorbett.com.au/diy-voice-over-booth/',
      },
    ],
  },
  {
    id: '013',
    index: 13,
    date: '2020-10-29',
    name: 'What\'s it like to be a Digital Producer at Starlight?',
    icon: <AiOutlineVideoCamera />,
    image: '/episodeImage/StarlightDigitalProducer.JPG',
    synopsis: 'Have you ever wondered what it takes to be a Digital Producer for the most innovative charity in Australia? In this episode I interview Jimmy about creating interactive digital content to help bring a smile to seriously ill and hospitalised children. We discuss the value of learning from your mistakes, Lego Masters and the awesome opportunities provided by technology!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Jimmy_Starlight.mp3',
    alt: 'cartoon of a stick figure holding a laptop and a camera',
    guest: {
      name: 'Jimmy Eaton',
      link: 'https://jimmyjameseaton.com/about',
      linkText: 'For more about Jimmy and his lively career have a look at his website',
      description: 'Digital Producer',
    },
    linkList: [
      {
        title: 'To donate or Volunteer head over to the Starlight Website',
        url: 'https://starlight.org.au/',
      },
      {
        title: 'The awesome Starlight LinkedIn Page',
        url: 'https://www.linkedin.com/company/starlight-children%27s-foundation-australia/',
      },
      {
        title: 'The seriously amazing Starlight YouTube Channel',
        url: 'https://www.youtube.com/user/StarlightAus',
      },
    ],
  },
  {
    id: '014',
    index: 14,
    date: '2020-11-05',
    name: 'What\'s it like to be a Vet?',
    icon: <FaCat />,
    image: '/episodeImage/ChantelleVet.JPG',
    synopsis: 'Being a vet is so many people\'s dream! In this episode I chat with Dr Chantelle about some of the awesome parts, and the challenges of working in the Veterinary industry. We talk about mental health, setting boundaries and finding a career that aligns with your values.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Chantelle_Vet.mp3',
    alt: 'cartoon of a person with cat ears presenting to two cats about how to have a happy cat',
    guest: {
      name: 'Dr Chantelle McGowan',
      link: 'https://www.instagram.com/dr_channy/',
      linkText: 'For more, follow Dr Chantelle on Instagram!',
      description: 'Veterinarian',
    },
    linkList: [
      {
        title: 'Love your Pet, Love your Vet Charity info here',
        url: 'https://www.facebook.com/loveyourpetloveyourvet',
      },
      {
        title: 'Beyond Blue',
        url: 'https://www.beyondblue.org.au/',
      },
      {
        title: 'Lifeline',
        url: 'https://www.lifeline.org.au/',
      },
    ],
  },
  {
    id: '015',
    index: 15,
    date: '2020-11-11',
    name: 'What\'s it like to be Diabetes Researcher?',
    icon: <RiMedicineBottleLine />,
    image: '/episodeImage/JudyDiabetes.JPG',
    synopsis: 'November 14th is World Diabetes Day which we are celebrating with a very special interview with Prof Judy who runs a  Diabetes research lab at the Baker institute. We discuss her career path, the joys and challenges of working in Science and the absolute importance of resilience. Tune in to hear all about her amazing cutting edge research in oxidative stress! ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Judy_Diabetes.mp3',
    alt: 'cartoon of a person with lab equiptment doing experiments',
    guest: {
      name: 'Professor Judy de Haan',
      link: 'https://baker.edu.au/research/staff/judy-dehaan',
      linkText: 'For more see Judy\'s page at the Baker Institute',
      description: 'Research Head: Oxidative Stress Laboratory',
    },
    linkList: [
      {
        title: 'For more about Diabetes research in Melbourne have a look at the Baker Institute',
        url: 'https://baker.edu.au/',
      },
      {
        title: 'Diabetes Australia',
        url: 'https://www.diabetesaustralia.com.au/',
      },
      {
        title: 'World Diabetes Day',
        url: 'https://worlddiabetesday.org/',
      },
    ],
  },
  {
    id: '016',
    index: 16,
    date: '2020-11-12',
    name: 'What\'s it like to be a Nanotech Researcher?',
    icon: <GiAtom />,
    image: '/episodeImage/ReneeNanotech.JPG',
    synopsis: 'In this episode I interview Dr Renee, a Physics lecturer and Nanotech researcher investigating the application of nanotech in improving people\'s health. We talk about quantum dots, finding good mentors, conductive worm experiments and engaging people in Physics!',
    path: '/podcastEpisodes/2020_Renee_Nanotech.mp3',
    alt: 'cartoon of a person with a laptop and a microscope',
    guest: {
      name: 'Dr Renee Goreham',
      link: 'https://www.newcastle.edu.au/profile/renee-goreham',
      linkText: 'For more and to ask questions, email Renee!',
      description: 'Nanotech Researcher',
    },
    linkList: [
      {
        title: 'How to do a Nappy Polymer experiment',
        url: 'https://edu.rsc.org/resources/experiments-with-hydrogels-hair-gel-and-disposable-nappies/689.article',
      },
      {
        title: 'One of Renee\'s very cool Physics cartoons',
        url: 'https://www.youtube.com/watch?v=bg1Ex69M8r0&t=52s',
      },
      {
        title: 'Fantastic Physics Resources from the University of Newcastle',
        url: 'https://www.newcastle.edu.au/faculty/science/science-in-practice/fantastic-physics',
      },
    ],
  },
  {
    id: '017',
    index: 17,
    date: '2020-11-19',
    name: 'What\'s it like to be a Livewire Facilitator?',
    icon: <FaLaptopCode />,
    image: '/episodeImage/Lori_Starlight.JPG',
    synopsis: 'In this episode I interview Lori from Livewire, an online moderated community for 12-20 year olds living with an illness or disability. We talk about the joy of working with teenagers, helping people connect online and the importance of being interesting and interested!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Lori_Starlight.mp3',
    alt: 'cartoon of a person with a laptop and speech bubbles',
    guest: {
      name: 'Lori Dwyer',
      link: 'https://starlight.org.au/',
      linkText: 'Click here for more about Starlight',
      description: 'Livewire Facilitator',
    },
    linkList: [
      {
        title: 'Check out the Livewire website here',
        url: 'https://livewire.org.au/',
      },
      {
        title: 'The Hamilton trailer on YouTube as promised is here',
        url: 'https://www.youtube.com/watch?v=DSCKfXpAGHc',
      },
      {
        title: 'The awesome Starlight LinkedIn Page',
        url: 'https://www.linkedin.com/company/starlight-children%27s-foundation-australia/',
      },
    ],
  },
  {
    id: '018',
    index: 18,
    date: '2020-11-26',
    name: 'What\'s it like to be a Business Analyst?',
    icon: <FaSuitcase />,
    image: '/episodeImage/BAAnushka.JPG',
    synopsis: 'In this episode I interview Anushka, a Business Analyst in the finance industry. We talk about adapting your career as your interests evolve, the importance of a BA in an Agile work environment and translating between business and technology.She highlights the importance of believing in yourself and taking calculated risks with your career!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Anushka_BA.mp3',
    alt: 'cartoon of a person with thought bubbles above them indicating ideas and documentation',
    guest: {
      name: 'Anushka Kahawela',
      link: 'https://www.instagram.com/anuushkah/?hl=en',
      linkText: 'To follow Anushka\'s adventures check out her Instagram',
      description: 'Business Analyst',
    },
    linkList: [
      {
        title: 'IT Webinars from Gartner',
        url: 'https://www.gartner.com/en/information-technology/events/webinars',
      },
      {
        title: 'A YouTube on starting a BA Career',
        url: 'https://www.youtube.com/watch?v=77AMjzImwLI',
      },
      {
        title: 'International Institute of Business Analytics Webinars',
        url: 'https://www.iiba.org/professional-development/knowledge-centre/webinars/webinar-archives/',
      },
    ],
  },
  {
    id: '019',
    index: 19,
    date: '2020-12-3',
    name: 'What\'s it like to be an Academic in Soil Science?',
    icon: <GiFlowerPot />,
    image: '/episodeImage/PreetiSoilScience.JPG',
    synopsis: 'A very special episode for Soil Science day. I interview Preeti, an expert soil scientist doing research on Acidic soil amelioration. We talk about the importance of critical thinking, the value of composting and how important it is to know that soil is not just dirt! ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Preeti_Soils.mp3',
    alt: 'Cartoon of a person with a flower pot, a flower growing in it and a shovel',
    guest: {
      name: 'Preeti Roychand',
      link: 'https://www.linkedin.com/in/preeti-roychand-83811718/?originalSubdomain=au',
      linkText: 'Connect with Preeti on LinkedIn',
      description: 'Soil Science Academic',
    },
    linkList: [
      {
        title: 'For more about soil have a look at Soil Science Australia!',
        url: 'https://www.soilscienceaustralia.org.au/',
      },
      {
        title: 'The Department of Agriculture Victoria',
        url: 'https://agriculture.vic.gov.au/',
      },
    ],
  },
  {
    id: '020',
    index: 20,
    date: '2020-12-03',
    name: 'What\'s it like to work in Medical Technology and Recruitment?',
    icon: <FaFileMedicalAlt />,
    image: '/episodeImage/TaraMedTech.JPG',
    synopsis: 'Tara works in an incredibly interesting world! She is the co-director of JEM Med Academy, an organisation dedicated to recruiting and training people to work in Medical Technology. We talk about what Med Tech includes, some of the skills you need to excel in the field and how valuable transferrable skills are when you\'re looking at transitioning into a new career! ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Tara_MedTech.mp3',
    alt: 'Cartoon of a stick figure with a medical suitcase',
    guest: {
      name: 'Tara Sharma',
      link: 'https://www.linkedin.com/in/tara-sharma-3141015/',
      linkText: 'Connect with Tara on LinkedIn',
      description: 'Director of JEM Med Academy',
    },
    linkList: [
      {
        title: 'To find out about JEM Med Academy and Med Tech Careers head to their website',
        url: 'https://www.jemmed.com.au/',
      },
      {
        title: 'Visit JEM Med on LinkedIn',
        url: 'https://www.linkedin.com/company/evolutionclinical/',
      },
    ],
  },
  {
    id: '021',
    index: 21,
    date: '2020-12-10',
    name: 'What\'s it like to research Stingrays?',
    icon: <GiFishhookFork />,
    image: '/episodeImage/CiaranStingrays.JPG',
    synopsis: 'This is a fascinating episode, I interview Ciaran, a Stingray researcher in Queensland! She talks about taking an amazing career journey away from PR into Marine Biology, with a growing love for Stingrays. We talk about how Stingrays create their own sunscreen and you can even revive a Stingray! ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Ciaran_Stingrays.mp3',
    alt: 'a cartoon of a snorkeller with a stingray on the bottom of the water',
    guest: {
      name: 'Ciaran Hyde',
      link: 'https://www.instagram.com/lifewithstingrays/?hl=en',
      linkText: 'To see Ciaran\'s work follow her on Instagram',
      description: 'Stingray Researcher',
    },
    linkList: [
      {
        title: 'The amazing Ray Facebook Photography Group',
        url: 'https://www.facebook.com/groups/2372401179742264/?ref=share',
      },
      {
        title: 'Stingray Colouring In!',
        url: 'https://avidresearchextras2021.s3-ap-southeast-2.amazonaws.com/colouringIn/StingrayColouringIn.pdf',
      },
      {
        title: 'Connect with Ciaran on LinkedIn',
        url: 'https://www.linkedin.com/in/ciaran-hyde-aa8929104',
      },
    ],
  },
  {
    id: '022',
    index: 22,
    date: '2020-12-17',
    name: 'What\'s it like to be a Civil Engineer?',
    icon: <GiTallBridge />,
    image: '/episodeImage/JoCivil.JPG',
    synopsis: 'Jo is an inspiring and delightful interviewee who has found exactly the right career! She\'s passionate about concrete and about helping people, and has brought the two things together as a Civil Engineer at Transport for New South Wales. We talk about Women in STEM, a love for maths and building confidence in a young generation of engineers!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Jo_CivilEngineer.mp3',
    alt: 'cartoon of a person with a roll of plans standing in front of a bridge',
    guest: {
      name: 'Jo Withford',
      link: 'https://www.linkedin.com/in/jo-withford-a7424184/?originalSubdomain=au',
      linkText: 'Connect with Jo on LinkedIn!',
      description: 'Civil Engineer',
    },
    linkList: [
      {
        title: 'See Science and Technology Australia for all kinds of STEM outreach',
        url: 'https://scienceandtechnologyaustralia.org.au/',
      },
      {
        title: 'The HunterWiSE Network',
        url: 'https://www.newcastle.edu.au/research/centre/hunterwise',
      },
      {
        title: 'To learn more about Engineering careers head over to Engineers Australia',
        url: 'https://www.engineersaustralia.org.au/',
      },
    ],
  },
  {
    id: '023',
    index: 23,
    date: '2020-12-26',
    name: 'Reflections on Podcasting in 2020',
    icon: <FaThinkPeaks />,
    image: '/episodeImage/2020Recap.JPG',
    synopsis: 'Well.. that was a year huh? Here\'s hoping 2021 is a little less exciting! However, I do want to do a tiny bit of celebrating! This is the 23rd episode since the podcast was launched in August. At the time, I was hoping to have 4 episodes by the end of the year, so.. this is a bit amazing! There\'s awesome episodes lined up until March next year, I\'ve got cartoons to work on, there\'s all kinds of stuff coming your way. Stay tuned! And please email me if you\'d like to be involved in asking questions for the coming interviews, I would love to include your curiosities!  You can hear all about it, and my generalised ramblings in this wrap episode! THANK YOU FOR BEING THE BEST AUDIENCE EVER! YOU ARE AWESOME!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_EOY_Reflection.mp3',
    alt: 'cartoon text of 2020',
    guest: undefined,
    linkList: [
      {
        title: 'Have a look at some awesome Instagram promo work here',
        url: 'https://www.instagram.com/avid_research/?hl=en',
      },
      {
        title: 'If you\'re more a Facebook person, check us out on Facebook',
        url: 'https://www.facebook.com/AvidResearch',
      },
      {
        title: 'The awesome Twitter feed is here',
        url: 'https://twitter.com/avid_podcast',
      },
      {
        title: 'Delightful YouTubes can be found here',
        url: 'https://www.youtube.com/channel/UCdPa9O8UXh9c1dG8FFVWfDQ',
      },
    ],
  },
  {
    id: '024',
    index: 24,
    date: '2021-01-08',
    name: 'What\'s it like to be a Facade Consultant?',
    icon: <FaBuilding />,
    image: '/episodeImage/LizetteFacades.JPG',
    synopsis: 'Lizette has a job that you possibly didn\'t know exists.. but now you do! As a Facade Consultant it\'s Lizette\'s job to make sure that buildings are safe, waterproof, fireproof and look good. Her job includes everything from researching how Bronze is manufactured to hosing down buildings to find leaks. She\'s an amazing person, doing some pretty cool things in the building industry, I think you\'ll like this one!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2020_Lizette_FacadeEngineer.mp3',
    alt: 'cartoon of a person holding a set of plans next to a tall building',
    guest: {
      name: 'Lizette McNeill',
      link: 'https://www.linkedin.com/in/lizette-mcneill-0b8b3060/?originalSubdomain=hk',
      linkText: 'Connect with Lizette on LinkedIn!',
      description: 'Facade Consultant',
    },
    linkList: [
      {
        title: 'Lizette\'s super amazing psrofile on Women in STEM',
        url: 'https://www.stemwomen.org.au/profile/lizette-mcneill',
      },
    ],
  },
  {
    id: '025',
    index: 25,
    date: '2021-01-13',
    name: 'What\'s it like to be a Heart Health Researcher?',
    icon: <GiHeartBeats />,
    image: '/episodeImage/NiamhHearts.JPG',
    synopsis: 'Niamh is an amazing Heart Health researcher in Hobart, who is discovering how we can use technology to reduce risk of heart attack and stroke. Not only that, but she is also the founder of That\'s what I call Science, a radio show and now podcast with some amazing Australian scientists. We talk about pretty much everything in this episode, from struggles at school, to travelling across the world for research. Niamh has had an amazing career to date, and has some great advice. Well worth tuning in!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Niamh_Menzies.mp3',
    alt: 'cartoon of a person standing with a clipboard and a giant heart',
    guest: {
      name: 'Niamh Chapman',
      link: 'https://twitter.com/nchapmanTAS',
      linkText: 'To get to know Niamh follow her on Twitter!',
      description: 'Heart Health Researcher',
    },
    linkList: [
      {
        title: 'That\'s what I call Science Website, where you can tune in to all their amazing podcasts',
        url: 'https://thatsscience.org/',
      },
      {
        title: 'That\'s what I call Science on Insta',
        url: 'https://www.instagram.com/thatssciencetas/',
      },
      {
        title: 'That\'s what I call Science on Twitter',
        url: 'https://twitter.com/ThatsScienceTAS',
      },
      {
        title: 'The awesome Deadly Science Project',
        url: 'https://deadlyscience.org.au/',
      },
    ],
  },
  {
    id: '026',
    index: 26,
    date: '2021-01-21',
    name: 'What\'s it like to be a Shark Researcher?',
    icon: <GiSharkFin />,
    image: '/episodeImage/Charlotte_Sharks.JPG',
    synopsis: 'Look beyond the teeth! That\'s one of the wonderful messages in this interview with Charlotte, shark researcher and Mandarin speaking science communicator. Tune in to hear about how coding can sneak into a marine biology job and the value in having goals but also remembering to look sideways.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Charlotte_Sharks.mp3',
    alt: 'cartoon of a person on a boat looking down at some sharks in the water',
    guest: {
      name: 'Charlotte Birkmanis',
      link: 'http://www.charlottebirkmanis.com/',
      linkText: 'Head over to Charlotte\'s website to learn more!',
      description: 'Shark Researcher',
    },
    linkList: [
      {
        title: 'She Codes - Coding workshops for women in Western Australia',
        url: 'https://shecodes.com.au/',
      },
      {
        title: 'Citizen Science program Reef Check',
        url: 'https://www.reefcheckaustralia.org/',
      },
      {
        title: 'Check out Scripps Institution of Oceanography for some cool livestreams',
        url: 'https://scripps.ucsd.edu/',
      },
      {
        title: 'RedMap species mapping in Australian Waters',
        url: 'https://www.redmap.org.au/',
      },
    ],
  },
  {
    id: '027',
    index: 27,
    date: '2021-01-28',
    name: 'What\'s it like to be an Animal Nutritionist?',
    icon: <GiChicken />,
    image: '/episodeImage/Shiva_Nutrition.JPG',
    synopsis: 'Shiva is not only working as an animal nutritionist, she\'s also studying a PhD in chicken nutrition! In this interview we talk about backing yourself, finding your way back to your passion and all the complexities of ensuring animals have healthy diets.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Shiva_Chickens.mp3',
    alt: 'cartoon of a person with a clipboard next to two happy chickens',
    guest: {
      name: 'Shiva Greenhalgh',
      link: 'https://www.linkedin.com/in/shiva-greenhalgh-77b000181/?originalSubdomain=au',
      linkText: 'Connect with Shiva on LinkedIn',
      description: 'Animal Nutritionist',
    },
    linkList: [
      {
        title: 'For more about Shiva\'s work head on over to her website',
        url: 'www.sydneyanimalnutrition.com.au ',
      },
      {
        title: 'Follow her nutrition adventures on Facebook!',
        url: 'https://www.facebook.com/Sydney-Animal-Nutrition-385706775328919/',
      },
    ],
  },
  {
    id: '028',
    index: 28,
    date: '2021-02-05',
    name: 'What\'s it like to be a Human Physiologist?',
    icon: <GiHeartInside />,
    image: '/episodeImage/HollieHealth.JPG',
    synopsis: 'Hollie is not only a delightful person, but she\'s also undertaking research to try and help people live healthier lives. We talk about the value of taking time to decide what you want to do, and the joys of working with young people. Hollie shares her experience working out what she was passionate about, and the importance of asking questions before committing to a PhD.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Hollie_Health.mp3',
    alt: 'cartoon of a person having an idea holding a laptop and a beaker',
    guest: {
      name: 'Hollie Speer',
      link: 'https://twitter.com/holliespeer',
      linkText: 'Follow Hollie on Twitter',
      description: 'Human Physiologist',
    },
    linkList: [
      {
        title: 'To track everything that\'s happening with a Pint of Science head to their website',
        url: 'https://pintofscience.com.au/',
      },
    ],
  },
  {
    id: '029',
    index: 29,
    date: '2021-02-11',
    name: 'What\'s it like to be a Neuropsychologist?',
    icon: <GiBrain />,
    image: '/episodeImage/KatieNeuro.JPG',
    synopsis: 'In this episode we interview Dr Kate, a clinical Neuropsychologist who also undertakes research. We talk about what Neuropsychology is, what it isn\'t and how she found her way into such an awesome career. Kate is full of awesome advice and wisdom that comes from working with people who have acquired brain injuries. ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Kate_Neuropsych.mp3',
    alt: 'cartoon of a researcher standing next to someone with a head wrapped in bandages',
    guest: {
      name: 'Dr Kate Gould',
      link: 'https://twitter.com/THINKFULLY_',
      linkText: 'Follow Kate on Twitter',
      description: 'Clinical Neuropsychologist and researcher',
    },
    linkList: [
      {
        title: 'Monash-Epworth Rehabilitation Research Centre',
        url: 'https://www.monash.edu/medicine/psych/research-programs/merrc',
      },
      {
        title: 'Turner Institute for Brain and Mental Health',
        url: 'https://www.monash.edu/turner-institute',
      },
      {
        title: 'Check out the awesome work by the Genyus Network',
        url: 'https://genyusnetwork.com/',
      },
      {
        title: 'Not to mention the great work by Brain Injury Australia',
        url: 'https://www.braininjuryaustralia.org.au/',
      },
    ],
  },
  {
    id: '030',
    index: 30,
    date: '2021-02-18',
    name: 'What\'s it like to be a Reptile Biologist?',
    icon: <FaFrog />,
    image: '/episodeImage/GeorgiaGoanna.JPG',
    synopsis: 'Dr Georgia lives an incredibly interesting life as a wildlife ecologist and Reptile Biologist! We talk about her amazing work helping teach predators in the North West of Australia learn not to eat cane toads by feeding them cane toad sausages! Georgia also talks about the ways that western science and traditional knowledge can work together and even a bit about the book that was written about her!',
    path: '/podcastEpisodes/2021_Georgia_Goannas.mp3',
    alt: 'cartoon of a researcher standing next to someone with a head wrapped in bandages',
    guest: {
      name: 'Dr Georgia Ward-Fear',
      link: 'https://twitter.com/g_wardfear?lang=en',
      linkText: 'Follow Georgia on Twitter',
      description: 'Reptile Biologist',
    },
    linkList: [
      {
        title: 'Learn about the Cane Toad Coalition here!',
        url: 'https://www.canetoadcoalition.com/',
      },
      {
        title: 'The awesome book about Georgia!',
        url: 'https://wilddingopress.com.au/product/aussie-stem-stars-georgia-ward-fear/',
      },
      {
        title: 'Goanna Colouring In',
        url: 'https://avidresearchextras2021.s3-ap-southeast-2.amazonaws.com/colouringIn/GoannaColouring.pdf',
      },
    ],
  },
  {
    id: '031',
    index: 31,
    date: '2021-02-25',
    name: 'What\'s it like to be a Reproductive Researcher?',
    icon: <MdPregnantWoman />,
    image: '/episodeImage/OliviaPlacentas.JPG',
    synopsis: 'In this episode I interview Dr Olivia about what life is like as a Reproductive Researcher, her focus is on the placenta and keeping things healthy for everyone during pregnancy. We talk about how amazing, and seriously under-appreciated, the placenta is. Olivia shares some wonderful advice for young people, and gives us a little insight into some of her previous research into possum reproduction!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Olivia_Placentas.mp3',
    alt: 'cartoon of a researcher with a laptop standing next to a table with a placenta on it',
    guest: {
      name: 'Dr Olivia Holland',
      link: 'https://twitter.com/oliviah36325452',
      linkText: 'Follow Olivia on Twitter',
      description: 'Reproductive Researcher',
    },
    linkList: [
      {
        title: 'Placenta Colouring in',
        url: 'https://avidresearchextras2021.s3-ap-southeast-2.amazonaws.com/PlacentaColouringIn.pdf',
      },
      {
        title: 'The Lions Medical Research Foundation',
        url: 'https://lionsmedicalresearchfoundation.org.au/',
      },
    ],
  },
  {
    id: '032',
    index: 32,
    date: '2021-03-05',
    name: 'What\'s it like to be a Marine Evolutionary Biologist?',
    icon: <GiWaveCrest />,
    image: '/episodeImage/PaigeNudis.JPG',
    synopsis: 'Paige is a Nudibranch researcher and works at the Perth Museum! Her research is investigating species of Nudibranch in Antarctic waters, she has some fantastic stories from life at sea and in the Museum! We talk about learning to take a breather now and again, and taking up opportunities (and not eating Nudibranchs...)',
    path: '/podcastEpisodes/2021_Paige_Nudibranchs.mp3',
    alt: 'cartoon of a person standing on a ship and a nudibranch under the water on a rock',
    guest: {
      name: 'Dr Paige Maroni',
      link: 'https://twitter.com/PaigeMaroni',
      linkText: 'Follow Paige on Twitter',
      description: 'Marine Evolutionary Biologist',
    },
    linkList: [
      {
        title: 'Nudibranch Colouring In',
        url: 'https://avidresearchextras2021.s3-ap-southeast-2.amazonaws.com/colouringIn/NudibranchColouring.pdf',
      },
      {
        title: 'Take 3 for the sea',
        url: 'https://www.take3.org/',
      },
      {
        title: 'The Western Australian Museum',
        url: 'http://museum.wa.gov.au/',
      },
      {
        title: 'Taxonomy Australia',
        url: 'https://www.taxonomyaustralia.org.au/',
      },
      {
        title: 'The Antarctic Treaty',
        url: 'https://www.ats.aq/e/protocol.html',
      },
      {
        title: 'The Madrid Protocol',
        url: 'https://www.antarctica.gov.au/about-antarctica/law-and-treaty/the-madrid-protocol/',
      },
      {
        title: 'Register of Antarctic Marine Species (RAMS)',
        url: 'http://www.marinespecies.org/rams/index.php',
      },
    ],
  },
  {
    id: '033',
    index: 33,
    date: '2021-03-11',
    name: 'What\'s it like to research Human Computer Interactions?',
    icon: <FaLaptop />,
    image: '/episodeImage/EliseComputers.JPG',
    synopsis: 'As a Human Computer Interaction Design researcher Prof Elise asks some incredible questions. Her job is to understand how computers impact our memories, and how we can use them to assist us in living better lives! We talk about how our interactions with technology is changing and the value of demonstrating your enthusiasm and listening to feedback.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Elise_InteractionDesign.mp3',
    alt: 'cartoon of a researcher with a laptop standing next to a table with different kinds of computers on it',
    guest: {
      name: 'Dr Elise Van Den Hoven',
      link: 'http://www.elisevandenhoven.com/',
      linkText: 'For more about Elise\'s work check out her website',
      description: 'Human Computer Interaction Design Researcher',
    },
    linkList: [
      {
        title: 'The Lucy Mentoring Program',
        url: 'https://www.uts.edu.au/about/faculty-engineering-and-information-technology/women-engineering-and-it/student-experience/lucy-mentoring-program',
      },
      {
        title: 'UTS Short Courses',
        url: 'https://open.uts.edu.au/uts-open/course-types/short-courses/',
      },
      {
        title: 'UTS Microcredentials',
        url: 'https://open.uts.edu.au/uts-open/course-types/microcredential-courses/',
      },
      {
        title: 'Women in Engineering and IT at UTS',
        url: 'https://www.uts.edu.au/about/faculty-engineering-and-information-technology/women-engineering-and-it',
      },
      {
        title: 'Industry sponsorship for women students at UTS',
        url: 'https://www.uts.edu.au/about/faculty-engineering-and-information-technology/women-engineering-and-it/scholarships-and-awards/sponsor-women-engineering-and-it-co-operative-scholarship',
      },
    ],
  },
  {
    id: '034',
    index: 34,
    date: '2021-03-18',
    name: 'What\'s it like to be an Antarctic Geoscientist?',
    icon: <FaHammer />,
    image: '/episodeImage/StephGeo.JPG',
    synopsis: 'Dr Steph has a fascinating job, working for Geoscience Australia as a Geologist. Her job is to understand the Antarctic landscape and undertake research to improve our management of it! In this episode we talk about changing career direction, finding community and the value of being able to think critically and create an argument!',
    path: '/podcastEpisodes/2021_Steph_Geoscience.mp3',
    alt: 'cartoon of a researcher standing next to an Antarctic mountain holding a hammer',
    guest: {
      name: 'Dr Steph McLennan',
      link: 'https://twitter.com/StephMcLenn',
      linkText: 'Follow Steph on Twitter',
      description: 'Antarctic Geoscientist',
    },
    linkList: [
      {
        title: 'Check out the amazing Deadly Science here',
        url: 'https://deadlyscience.org.au/',
      },
      {
        title: 'Antarctic Colouring in Download',
        url: 'https://avidresearchextras2021.s3-ap-southeast-2.amazonaws.com/colouringIn/AntarcticColouring.pdf',
      },
    ],
  },
  {
    id: '035',
    index: 35,
    date: '2021-03-23',
    name: 'What\'s it like to develop cultured meat?',
    icon: <FaHamburger />,
    image: '/episodeImage/KatieVow.JPG',
    synopsis: 'Katie has a very different kind of science job, she\'s a researcher at a cultured meat startup in Sydney called Vow. They\'re working to not only create sustainable meats in a lab environment, but also to make them even tastier than traditional meat! Katie shares some wonderful advice in this episode, and also sheds some light on some of the food innovations we can look forward to in the coming years!',
    path: '/podcastEpisodes/2021_Katie_Vow.mp3',
    alt: 'cartoon of a researcher standing next to a table with lab grown meat',
    guest: {
      name: 'Katie Bashant Day',
      link: 'https://www.linkedin.com/in/kathleenbashantday/',
      linkText: 'Connect with Katie on LinkedIn',
      description: 'Research Scientist II',
    },
    linkList: [
      {
        title: 'Find out more about Vow here',
        url: 'https://www.vowfood.com/',
      },
      {
        title: 'Cultured Meat Colouring in Download',
        url: 'https://avidresearchextras2021.s3-ap-southeast-2.amazonaws.com/colouringIn/YummyLabGrownColouring.pdf',
      },
    ],
  },
  {
    id: '036',
    index: 36,
    date: '2021-04-01',
    name: 'What\'s it like to write a kids book about the Ocean?',
    icon: <GiBookPile />,
    image: '/episodeImage/PattieAnnieOceans.JPG',
    synopsis: 'In this episode we hear Patricia and Annie\'s story of how they collaborated to create their newly released book, Planet Ocean. They talk about their motivations for creating this book, share some fun marine facts, and give some really great advice about how to stay positive when learning about the global issues our world is facing.',
    path: '/podcastEpisodes/2021_PattieAnnie_Book.mp3',
    alt: 'cartoon of two people on a sailing boat, one holding a camera the other holding a book',
    guest: {
      name: 'Patricia Newman and Annie Crawley',
      link: 'https://www.youtube.com/watch?v=O23HCqlu0ek',
      linkText: 'Watch the Planet Ocean Trailer',
      description: 'Creators of the Plant Ocean Book',
    },
    linkList: [
      {
        title: 'Patricia\'s website',
        url: 'https://www.patriciamnewman.com/',
      },
      {
        title: 'Annie\'s website',
        url: 'https://www.anniecrawley.com/',
      },
      {
        title: 'Planet Ocean on Amazon',
        url: 'https://www.amazon.com.au/Planet-Ocean-Why-Need-Healthy/dp/1541581210',
      },
      {
        title: 'Planet Ocean on Book Depository',
        url: 'https://www.bookdepository.com/Planet-Ocean-Patricia-Newman-Annie-Crawley/9781541581210',
      },
    ],
  },
  {
    id: '037',
    index: 37,
    date: '2021-04-02',
    name: 'What\'s it like to study hurricanes and coral reefs?',
    icon: <GiWhirlwind />,
    image: '/episodeImage/MarjiReefs.JPG',
    synopsis: 'In this episode I interview Marji about her life as a spatial ecologist and researcher of coral reefs. We talk about her very entertaining and educational career journey and how she\'s ended up helping marine research projects in Western Australia get efficient effective data! She also shares some of her adventures in science outreach working to inspire the next generation.',
    path: '/podcastEpisodes/2021_Marji_Corals.mp3',
    alt: 'cartoon of a person on a boat holding a map and a laptop',
    guest: {
      name: 'Dr Marji Puotinen',
      link: 'https://twitter.com/marji_puotinen',
      linkText: 'Follow Marji on Twitter',
      description: 'Spatial Ecologist',
    },
    linkList: [
      {
        title: 'The contest website for Marji\'s 2021 competition!',
        url: 'https://kidscareaboutclimate.org',
      },
      {
        title: 'A YouTube to get you fired up about this awesome competition',
        url: 'https://youtu.be/KRNjSuRQYnU',
      },
      {
        title: 'Highlights from the 2018 contest',
        url: 'https://youtu.be/AY7IDV5YOJM',
      },
      {
        title: 'AIMS - The institute of Marine Science',
        url: 'https://aims.gov.au/',
      },
    ],
  },
  {
    id: '038',
    index: 38,
    date: '2021-04-15',
    name: 'What\'s it like to work in a Zoology Museum?',
    icon: <GiPolarBear />,
    image: '/episodeImage/JackZoology.JPG',
    synopsis: 'In this interview with Jack from the Museum of Zoology in Cambridge we talk about everything Australian mammal. From how they\'re percieved overseas to some very fun facts about some of our favourite creatures. Jack shares some of his advice for getting a job in a Museum and we get a bit of a behind the scenes look at what goes into keeping a Museum alive and well!',
    path: '/podcastEpisodes/2021_Jack_Zoology.mp3',
    alt: 'cartoon of a person in a hat standing next to a tree, a wombat and a platypus',
    guest: {
      name: 'Jack Ashby',
      link: 'https://twitter.com/JackDAshby',
      linkText: 'Follow Jack on Twitter',
      description: 'Deputy Director of the Museum of Zoology, Cambridge',
    },
    linkList: [
      {
        title: 'Jacks\'s Animal Kingdom Book',
        url: 'https://www.thehistorypress.co.uk/publication/animal-kingdom/9780750981521/',
      },
      {
        title: 'Museum of Zoology, University of Cambridge Website',
        url: 'https://www.museum.zoo.cam.ac.uk/',
      },
      {
        title: 'Catch up on the Battle of the Beasts!',
        url: 'https://www.youtube.com/watch?v=wMLBGR569cE',
      },
    ],
  },
  {
    id: '039',
    index: 39,
    date: '2021-04-22',
    name: 'What\'s it like to research bird song?',
    icon: <FaEarlybirds />,
    image: '/episodeImage/DominiqueBirds.JPG',
    synopsis: 'In this episode I chat with Dr Dominique about her career as a bird song researcher (behavioural ecologist). We talk about the impacts of sound pollution and bird song and bird accents even! She highlights the importance of passion, insight and curiosity, and gives a great shoutout to bird watchers contributing to citizen science!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Dominique_Birds.mp3',
    alt: 'cartoon of someone holding a sound dish at some birds singing in a tree',
    guest: {
      name: 'Dr Dominique Potvin',
      link: 'https://twitter.com/silvereyedoc',
      linkText: 'Follow Dominique on Twitter',
      description: 'Lecturer, Animal Ecology',
    },
    linkList: [
      {
        title: 'Visit Dominiques website',
        url: 'https://www.potvinlab.net/',
      },
      {
        title: 'Contribute to the Backyard Bird Count',
        url: 'https://aussiebirdcount.org.au/',
      },
      {
        title: 'Check out the Atlas of Living Australia',
        url: 'https://www.ala.org.au/',
      },
    ],
  },
  {
    id: '040',
    index: 40,
    date: '2021-04-26',
    name: 'What\'s it like to work in R&D in water recycling?',
    icon: <FaWater />,
    image: '/episodeImage/KathyWater.JPG',
    synopsis: 'Dr Kathy shares some wonderful stories about her career in, mostly, water treatment. We discuss investigating out how to clean up diesel spills in Antarctica and helping mentor younger researchers to talk about their research. She\'s got a pretty awesome career map too!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Kathy_Water.mp3',
    alt: 'cartoon of a stick figure holding a beaker standing next to a river and some wind turbines',
    guest: {
      name: 'Dr Kathy Northcott',
      link: 'https://www.linkedin.com/in/kathy-northcott-55a76643/',
      linkText: 'Connect with Kathy on LinkedIn',
      description: 'Research & Development Manager',
    },
    linkList: [
      {
        title: 'For more about Veolia ANZ head to their website',
        url: 'https://www.veolia.com/anz/',
      },
      {
        title: 'Learn about Water Watch, Victoria\'s citizen science water analysis program',
        url: 'http://www.vic.waterwatch.org.au/',
      },
    ],
  },
  {
    id: '041',
    index: 41,
    date: '2021-05-05',
    name: 'What\'s it like to be a Breast Cancer Researcher?',
    icon: <GiSwordwoman />,
    image: '/episodeImage/AmyBreastCancer.JPG',
    synopsis: 'Dr Amy researches Breast Cancer, specifically she\'s interested in the kinds of cancers you don\'t inherit. In this episode we dive into what cancer actually is, and the impacts that the ability to sequence genomes of cancers has made on research. We also touch a bit on ethics and trusting your gut a bit when it comes to your career! ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Amy_Breastcancer.mp3',
    alt: 'cartoon of a researcher with a computer looking at a scan of breasts',
    guest: {
      name: 'Dr Amy McCart',
      link: 'https://twitter.com/amymccart',
      linkText: 'Follow Amy on Twitter',
      description: 'Senior Research Fellow, Molecular Breast Pathology',
    },
    linkList: [
      {
        title: 'The UQ center for Clinical Research',
        url: 'https://clinical-research.centre.uq.edu.au/',
      },
      {
        title: 'Breast Cancer Network Australia',
        url: 'https://www.bcna.org.au/',
      },
    ],
  },
  {
    id: '042',
    index: 42,
    date: '2021-05-12',
    name: 'What\'s the deal with the Career Maps?',
    icon: <FaMapMarkedAlt />,
    image: '/episodeImage/CareerMap.JPG',
    synopsis: 'In this episode I have a bit of a chat about what the career maps are, get a bit excited about somne of my theories about how they could help people beat imposter syndrome and invite you to come see them at an exhibition in Melbourne in July!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_CareerMaps.mp3',
    alt: 'cartoon of a very vague career map',
    guest: undefined,
    linkList: [
      {
        title: 'Check out some of the career maps',
        url: 'https://avidresearch.com.au/maps/',
      },
      {
        title: 'Head over to the Libraries website',
        url: 'https://www.melbourne.vic.gov.au/community/libraries/whats-on/exhibitions/Pages/exhibitions.aspx',
      },
    ],
  },
  {
    id: '043',
    index: 43,
    date: '2021-05-19',
    name: 'What\'s it like to work in a Nuclear Power Plant?',
    icon: <GiNuclear />,
    image: '/episodeImage/JoNuclear.JPG',
    synopsis: 'For this episode I got to chat with the amazing Dr Jo who is the one and only Regulatory and Licencing officer at the Opal Reactor. We talk about what it\'s like to work in a nuclear reactor and a lot about some of the misconceptions about nuclear including some of the inaccuracies in the Simpsons. Please note there was a slight misspeak, Jo mentioned that fusion requires temperatures a million times hotter than the center of the sun. It\'s actually more like several times hotter, not a million times, still incredibly hot!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Jo_ANSTO.mp3',
    alt: 'cartoon of someone standing next to a nuclear reactor',
    guest: {
      name: 'Dr Jo Lackenby',
      link: 'https://www.linkedin.com/in/joanne-lackenby-0a299531/',
      linkText: 'Connect with Dr Jo on LinkedIn',
      description: 'Regulatory and Licencing officer, Opal Reactor, ANSTO',
    },
    linkList: [
      {
        title: 'For more about ANSTO',
        url: 'https://www.ansto.gov.au/',
      },
      {
        title: 'The International Atomic Agency\'s website',
        url: 'https://www.iaea.org/',
      },
      {
        title: 'Our World in Data Website',
        url: 'https://ourworldindata.org/',
      },
    ],
  },
  {
    id: '044',
    index: 44,
    date: '2021-05-25',
    name: 'Lets bust some career myths!',
    icon: <GiIdea />,
    image: '/episodeImage/MythEpisode.JPG',
    synopsis: 'In this episode I had a lot of ideas to get off my chest after the National Careers Week. We did some brainstorming about some of the myths that are out there, from "You\'re wasted if you go to TAFE" to "Year 12 is the most important year of your life". I talk about a number of the myths out there, and my opinions and experiences with them and encourage you to let me know what myths I missed!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_CareerMyths.mp3',
    alt: 'cartoon that says SUCH MYTH',
    guest: undefined,
    linkList: [
      {
        title: 'For more about Careers Week',
        url: 'https://careersweek.com.au/',
      },
      {
        title: 'The myfuture website',
        url: 'https://myfuture.edu.au/',
      },
    ],
  },
  {
    id: '045',
    index: 45,
    date: '2021-06-01',
    name: 'What\'s it like to run an edible bugs business?',
    icon: <GiCricket />,
    image: '/episodeImage/SkyeBugs.JPG',
    synopsis: 'Sky is the Founder of Circle Harvest, formerly the Bug Shop. Australia\'s first edible insect shop. We talk about the importance of sustainable food choices and how wonderfully surprisingly nutritious crickets are! We also talk about supporting your children\'s passions and curiosity!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Skye_Bugs.mp3',
    alt: 'cartoon of someone standing next to a shop selling edible bugs',
    guest: {
      name: 'Skye Blackburn',
      link: 'https://twitter.com/Bugshop1',
      linkText: 'Follow Skye on Twitter',
      description: 'Bug shop Founder',
    },
    linkList: [
      {
        title: 'To order your own cricket cornchips head over to the Circle Harvest Website',
        url: 'https://circleharvest.com.au/',
      },
      {
        title: 'GoTerra',
        url: 'https://goterra.com.au/',
      },
    ],
  },
  {
    id: '046',
    index: 46,
    date: '2021-06-09',
    name: 'What\'s it like to research Dementia using Phenomics?',
    icon: <GiBrainStem />,
    image: '/episodeImage/LukePhenomics.JPG',
    synopsis: 'Special Episode for the Pint of Science Festival! A wonderful chat with Dr Luke who is a researcher of healthy ageing. Specifcially he\'s interested in how we can apply the science of Phenomics to understanding, and hopefully treating, Dementia. Tune in to hear a wonderful career journey and all about some of the stories your urine can tell scientists!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Luke_Dimentia.mp3',
    alt: 'cartoon of someone with an idea standing next to a diagram that shows lifestyle, environment and genetics leading into health',
    guest: {
      name: 'Dr Luke Whiley',
      link: 'https://twitter.com/_lukewhiley',
      linkText: 'Follow Luke on Twitter',
      description: 'Phenomics and Healthy Aging Researcher',
    },
    linkList: [
      {
        title: 'For more about the Pint of Science Festival',
        url: 'https://pintofscience.com.au/',
      },
      {
        title: 'Australian National Phenome Centre website',
        url: 'https://www.murdoch.edu.au/research/anpc',
      },
    ],
  },
  {
    id: '047',
    index: 47,
    date: '2021-06-16',
    name: 'A reverse Avid Research Interview!',
    icon: <FaMicrophoneAlt />,
    image: '/episodeImage/ReverseInterviewAmelia.JPG',
    synopsis: 'In this episode the tables get turned, and I get interviewed by the amazing Allison from the Australian Academy of Science about what it\'s like to be a STEM Storytelling Podcaster. We talk about my career (bits of it), why I chose the podcast platform, and what role the STEM Women Network has played in the success of my podcast. Tune in for a bit of a wild ride!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_ReverseInterview.mp3',
    alt: 'cartoon of someone sitting behind a microphone with an idea bubble and a speech bubble',
    guest: {
      name: 'Allison Hornery',
      link: 'https://www.linkedin.com/in/allisonhornery/',
      linkText: 'Connect with Allison on LinkedIn',
      description: 'Director of Cofluence and Diversity and Inclusion Programs Officer at The Australian Academy of Science',
    },
    linkList: [
      {
        title: 'The Australian Academy of Science',
        url: 'https://www.science.org.au/',
      },
      {
        title: 'Follow Allison on Twitter',
        url: 'https://twitter.com/allisonhornery',
      },
      {
        title: 'Join the STEM Women Network',
        url: 'https://www.stemwomen.org.au/',
      },
    ],
  },
  {
    id: '048',
    index: 48,
    date: '2021-06-07',
    name: 'What\'s it like to be an Anaesthetist?',
    icon: <GiNightSleep />,
    image: '/episodeImage/PatAnaesth.JPG',
    synopsis: 'Dr Patricia is a wonderful Anaesthetist, just the kind of person you would want to have next to you as you went into Surgery. Her job is in critical care, when moments matter between life and death. We talk about the realities of life in medicine, finding a job that suits your personality and touch a bit on the nature of conciousness!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Pat_Anesth.mp3',
    alt: 'cartoon of an anaesthetist',
    guest: {
      name: 'Dr Patricia',
      link: '#',
      linkText: '',
      description: 'Anaesthetist',
    },
    linkList: [
      {
        title: 'Emotional Female by Yumiko Kadota',
        url: 'https://www.penguin.com.au/books/emotional-female-9781760894627',
      },
      {
        title: 'Dollhouse the TV show',
        url: 'https://en.wikipedia.org/wiki/Dollhouse_(TV_series)',
      },
      {
        title: 'Westworld the TV show',
        url: 'https://en.wikipedia.org/wiki/Westworld_(TV_series)',
      },
    ],
  },
  {
    id: '049',
    index: 49,
    date: '2021-07-14',
    name: 'What\'s it like to be a Genomics Researcher?',
    icon: <FaDna />,
    image: '/episodeImage/ErinGenomics.JPG',
    synopsis: 'Dr Erin has a fascinating job with CSIRO working out how to extract DNA from specimines in formalin. She is using this knowledge to help conserve threatened species and understand how animals have adapted to changes in the past. We talk about how this WON\'T be used for cloning and some of her reflections on communicating with policy makers, plus much more!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Erin_Genomics.mp3',
    alt: 'cartoon of a person standing next to a specimine in a jar of formalin thinking about DNA',
    guest: {
      name: 'Dr Erin Hahn',
      link: 'https://twitter.com/erin_e_hahn',
      linkText: 'Follow Dr Erin on Twitter',
      description: 'Conservation Geneticist/Epigeneticist',
    },
    linkList: [
      {
        title: 'Have a look at Erin\'s Webiste',
        url: 'https://www.erinhahn.info/',
      },
      {
        title: 'Learn about Formalin sequencing (including a cane toad!)',
        url: 'https://www.biorxiv.org/content/10.1101/2021.04.18.440380v1.abstract',
      },
      {
        title: 'Read Erins review on museum epigenomics',
        url: 'https://www.sciencedirect.com/science/article/pii/S0169534719303477',
      },
      {
        title: 'Erins pronghorn paper on museum specimens',
        url: 'https://wildlife.onlinelibrary.wiley.com/doi/full/10.1002/jwmg.21810',
      },
    ],
  },
  {
    id: '050',
    index: 50,
    date: '2021-07-20',
    name: 'What\'s it like to investigate COVID-19 in waste water?',
    icon: <FaWater />,
    image: '/episodeImage/KellyWater.JPG',
    synopsis: 'Dr Kelly is a facilitator of science, assisting water organisations around Australia to collaborate and innovate. We talk about developing your skills in networking and asking questions. She also shares some great stories about the COVID-19 water testing and the morning flush. Listen in to find out more.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Kelly_Water.mp3',
    alt: 'cartoon of a person standing next to a giant test tube of water thinking about water',
    guest: {
      name: 'Dr Kelly Hill',
      link: 'https://twitter.com/erin_e_hahn',
      linkText: 'Learn more about Dr Kelly\'s work',
      description: 'Project Director: Collaboration on Sewage Surveillance of SARS-CoV-2 (“ColoSSoS”)',
    },
    linkList: [
      {
        title: 'Watch an interview with Kelly',
        url: 'https://www.waterra.com.au/publications/latest-news/2020/watch-colossos-project-director-dr-kelly-hill/',
      },
      {
        title: 'Learn all about the ColoSSoS project',
        url: 'https://www.waterra.com.au/research/communities-of-interest/covid-19/',
      },
    ],
  },
  {
    id: '051',
    index: 51,
    date: '2021-07-28',
    name: 'What\'s it like to run a Rum Distillery?',
    icon: <FaWineBottle />,
    image: '/episodeImage/TarrantRum.JPG',
    synopsis: 'In this episode we talk rum! Specifically what it takes to start and run a rum distillery in Tasmania. Tarrant shares some of the science behind distilling, and barrel ageing. And we talk about the challenges of starting a business, pivoting during a pandemic, and bringing a little bit of nerdiness into product names!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Tarrant_Rum.mp3',
    alt: 'cartoon of a person with rum standing next to a distilling apparatus',
    guest: {
      name: 'Tarrant Derksen',
      link: 'https://www.instagram.com/newnorfolkdistillery',
      linkText: 'Follow the distilling adventures on Instagram',
      description: 'Director - New Norfolk Distillery',
    },
    linkList: [
      {
        title: 'Check out New Norfolk Distillery here',
        url: 'https://www.newnorfolkdistillery.com/',
      },
      {
        title: 'For more awesome things to do in the Derwent Valley',
        url: 'https://derwentvalleytasmania.org.au/',
      },
    ],
  },
  {
    id: '052',
    index: 52,
    date: '2021-08-17',
    name: 'Celebrating 1 year of Avid Research!',
    icon: <FaBirthdayCake />,
    image: '/episodeImage/1stBday.JPG',
    synopsis: 'In this episode we are celebrating one whole year of Avid Research! I have a chat about some of the challenges of doing a podcast, and some of the benefits. It would be awesome to hear some feedback from you! What do you like about the podcast? Are there guests you think would be particularly cool to have on the show?',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/natSciWk2021.mp3',
    alt: 'cartoon of a card saying happy 1st birthday',
    guest: undefined,
    linkList: [
      {
        title: 'What\'s on in National Science Week',
        url: 'https://www.scienceweek.net.au/',
      },
      {
        title: 'Join the STEM Women Network',
        url: 'https://www.stemwomen.org.au/',
      },
    ],
  },
  {
    id: '053',
    index: 53,
    date: '2021-09-01',
    name: 'What\'s it like to work in Cellular Agriculture?',
    icon: <GiGooeyMolecule />,
    image: '/episodeImage/RuthCellAg.JPG',
    synopsis: 'In this episode I chat with Ruth, an amazing scientist with 3 jobs. Ruth not only volunteers to help communicate the science behind Cellular Agriculture but she does a bit of COVID Sewage testing and also is working with Nourish Ingredients to help them ferment animal free fats. Turns out you can ferment fats out of yeast!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Ruth_CellAg.mp3',
    alt: 'cartoon of a person thinking they love science next to a plate of cell ag meat',
    guest: {
      name: 'Ruth Purcell',
      link: 'https://twitter.com/RuthAmyPurcell',
      linkText: 'Follow Ruth\'s research on Twitter',
      description: 'Cellular Scientist',
    },
    linkList: [
      {
        title: 'Learn more about Cellular Agriculture Australia',
        url: 'https://cellularagricultureaustralia.org/',
      },
      {
        title: 'Learn more about Nourish Ingredients and fermenting fats',
        url: 'https://nourishing.io/',
      },
    ],
  },
  {
    id: '054',
    index: 54,
    date: '2021-09-07',
    name: 'What\'s it like to be an Academic and Entrepreneur?',
    icon: <GiDeliveryDrone />,
    image: '/episodeImage/KarenDrones.JPG',
    synopsis: 'In this episode I chat with Dr Karen, a lecturer in GIS and founder of multiple spatial organisations. We talk about STEM education using drones an dsome of the fantastic applications for drone data! If you have a drone there are so many ways you can contribute to science, check it out!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Karen_Drones.mp3',
    alt: 'cartoon of a person thinking about maps next to a drone',
    guest: {
      name: 'Dr Karen Joyce',
      link: 'https://twitter.com/KEJoyce2',
      linkText: 'Follow Karen\'s work on Twitter',
      description: 'Academic and Entrepreneur',
    },
    linkList: [
      {
        title: 'Learn more about SheMaps STEM Education programs',
        url: 'https://shemaps.com/',
      },
      {
        title: 'Contribute to collating drone data with GeoNadir',
        url: 'https://www.geonadir.com/?r_done=1',
      },
    ],
  },
  {
    id: '055',
    index: 55,
    date: '2021-09-14',
    name: 'What\'s it like to be a Structural Geologist?',
    icon: <FaMountain />,
    image: '/episodeImage/MelanieGeol.JPG',
    synopsis: 'This episode is a wonderful interview with Dr Melanie about her work as a Structural Geologist. We talk about the mechanics of how mountain ranges are created and the importance of Geology in sustainable futures. We also chat about using social media for science communication and sharing stories!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Melanie_Geol.mp3',
    alt: 'cartoon of a person standing in front of mountains',
    guest: {
      name: 'Dr Melanie Finch',
      link: 'https://twitter.com/melaniefinch_',
      linkText: 'Follow Melanie\'s work on Twitter',
      description: 'Structural Geologist',
    },
    linkList: [
      {
        title: 'Melanie in the Superstars of STEM program 2021-2022',
        url: 'https://scienceandtechnologyaustralia.org.au/profile/melanie-finch/',
      },
      {
        title: 'For more about Women in Earth, Environmental and Atmospheric Sciences Australasia',
        url: 'https://www.womeesa.net/database',
      },
    ],
  },
  {
    id: '056',
    index: 56,
    date: '2021-09-22',
    name: 'What\'s it like to work in Marine Conservation and Knowledge Visualisation?',
    icon: <FaPencilRuler />,
    image: '/episodeImage/JessDesign.JPG',
    synopsis: 'In this episode I chat with Dr Jess who is not only an amazing Marine Ecological Modeller but has also started her own business communicating knowledge. We talk about combining visual communication skills and maths and finding problems that you\'re passionate about solving!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Jess_Design.mp3',
    alt: 'cartoon of a person thinking about fish and with poster of designs',
    guest: {
      name: 'Dr Jess Hopf',
      link: 'https://twitter.com/JKHopf',
      linkText: 'Follow Jess\'s work on Twitter',
      description: 'Marine Conservation and Knowledge Visualisation',
    },
    linkList: [
      {
        title: 'Check out Knowlegible Designs here',
        url: 'https://knowlegible.design/',
      },
      {
        title: 'Follow Knowlegible Designs on Twitter',
        url: 'https://twitter.com/KnowlegibleDsg',
      },
    ],
  },
  {
    id: '057',
    index: 57,
    date: '2021-09-28',
    name: 'What\'s it like to teach heresy?',
    icon: <FaRegLightbulb />,
    image: '/episodeImage/LindaStats.JPG',
    synopsis: 'Dr Linda not only has an amazing career path, but she is also working to teach others how to raise young people to think critically and apply data to solve complex problems. We talk about the power of teenagers if we give them real tools, what being a heretic actually involves and how important it is to understand data and apply it meaningfully!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Linda_Heretics.mp3',
    alt: 'cartoon of a person thinking about statistics holding a book called Raising Heretics',
    guest: {
      name: 'Dr Linda McIver',
      link: 'https://twitter.com/lindamciver',
      linkText: 'Follow Linda\'s work on Twitter',
      description: 'Author and Educator',
    },
    linkList: [
      {
        title: 'For all about the Australian Data Science Education Institute head here',
        url: 'https://adsei.org/',
      },
      {
        title: 'Check out the amazing work being done by Pawsey',
        url: 'https://learn.pawsey.org.au/',
      },
      {
        title: 'Book Recommendation: Automating Inequality',
        url: 'https://us.macmillan.com/books/9781250074317',
      },
      {
        title: 'Book Recommendation: Weapons of Math Destruction',
        url: 'https://www.penguin.com.au/books/weapons-of-math-destruction-9780141985411',
      },
      {
        title: 'Book Recommendation: Made by Humans',
        url: 'https://www.mup.com.au/books/made-by-humans-paperback-softback',
      },
    ],
  },
  {
    id: '058',
    index: 58,
    date: '2021-10-07',
    name: 'What\'s it like to work in Mineral Exploration Research?',
    icon: <FaMountain />,
    image: '/episodeImage/CarolineGeol.JPG',
    synopsis: 'In this episode I caht with Associate Professor Caroline about her research and work as part of the MinEx CRC. We discuss the challenges and opportunities in mineral exploration, the joys of Geology and the importance of sustainable mining for a healthy future!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Caroline_Geol.mp3',
    alt: 'cartoon of a person thinking about rocks with a mining pic',
    guest: {
      name: 'Associate Professor Caroline Tiddy',
      link: 'https://twitter.com/CarolineTiddy',
      linkText: 'Follow Caroline on Twitter',
      description: 'Mineral Exploration Research and Education',
    },
    linkList: [
      {
        title: 'Learn about MinEx CRC Here',
        url: 'https://minexcrc.com.au/',
      },
      {
        title: 'For more about Associate Professor Caroline',
        url: 'https://people.unisa.edu.au/Caroline.Tiddy',
      },
    ],
  },
  {
    id: '059',
    index: 59,
    date: '2021-10-12',
    name: 'What\'s it like to work in Reproductive Research?',
    icon: <MdPregnantWoman />,
    image: '/episodeImage/JarrodRepro.JPG',
    synopsis: 'This week I chat with Dr Jarrod, a reproductive researcher about his research with the only known menstruating mouse, the Egyptian Spiny Mouse! We talk about taking breaks when you\'re getting burned out along with busting a LOT of myths! ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Jarrod_Repro.mp3',
    alt: 'cartoon of a person thinking about a month holding a little mouse',
    guest: {
      name: 'Dr Jarrod McKenna',
      link: 'https://twitter.com/jarrod_mckenna',
      linkText: 'Follow Jarrod\'s work on Twitter',
      description: 'Reproductive Biologist',
    },
    linkList: [
      {
        title: 'Connect with Dr Jarrod on LinkedIn',
        url: 'https://www.linkedin.com/in/jarrod-mckenna-942382128/?originalSubdomain=au',
      },
    ],
  },
  {
    id: '060',
    index: 60,
    date: '2021-10-19',
    name: 'What\'s it like to research Seaweed?',
    icon: <GiAtSea />,
    image: '/episodeImage/AlexSeaweed.JPG',
    synopsis: 'In this episode I chat with Dr Alex about all things seaweed. We talk about seaweed as a food source and the wonderful umami flavour it can add to food. We also discuss opportunities in marine research and the realities of living in a world impacted by climate change. ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Alexandra_Seaweed.mp3',
    alt: 'cartoon of a person swimming with seaweed holding a clipboard',
    guest: {
      name: 'Dr Alexandra Campbell',
      link: 'https://twitter.com/Dr_AlexandraCam',
      linkText: 'Follow Alex\'s work on Twitter',
      description: 'Senior Lecturer in Bioscience',
    },
    linkList: [
      {
        title: 'For more about Alex\'s research head here',
        url: 'https://www.alexandracampbell.com.au/',
      },
      {
        title: 'Sign up for some seaweed citizen science',
        url: 'http://www.usc.edu.au/seaweed#citizen-science',
      },
    ],
  },
  {
    id: '061',
    index: 61,
    date: '2021-10-26',
    name: 'SciCommSeptember Special 1',
    icon: <GiTalk />,
    image: '/episodeImage/SciCommSept.JPG',
    synopsis: 'This is the first of three special episodes where I talk with Helen, Tori and Maria about their involvement in the Science Communication Challenges #SciCommSeptember. We talk about the challenges facing researchers on social media and the benefits of connecting with other researchers!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_SciCommSept_1.mp3',
    alt: 'cartoon of science related icons around the word SciCommSeptember',
    guest: undefined,
    linkList: [
      {
        title: 'Dr Helen Williams',
        url: 'https://twitter.com/ScienceHelen',
      },
      {
        title: 'Tori Berezowski',
        url: 'https://twitter.com/BerezowskiTori',
      },
      {
        title: 'Dr Maria Cabrera',
        url: 'https://twitter.com/CabreraMarie',
      },
    ],
  },
  {
    id: '062',
    index: 62,
    date: '2021-11-03',
    name: 'What\'s it like to work at the Defence Science and Technology Group?',
    icon: <GiShield />,
    image: '/episodeImage/DSTG.JPG',
    synopsis: 'In this episode I interview Dr Mel and Vanessa about what it\'s like to work at the Defence Science and Technology Group as a Researcher. We talk about the opportunities available to Scientists through the Navigate program and about some of the research that is undertaken with Defence. We also touch on the importance of mentors and peers!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_DSTG.mp3',
    alt: 'cartoon of two people standing next to a ship',
    guest: undefined,
    linkList: [
      {
        title: 'For more about the Navigate Program click here',
        url: 'https://www.dst.defence.gov.au/careers/navigate',
      },
    ],
  },
  {
    id: '063',
    index: 63,
    date: '2021-11-10',
    name: 'What\'s it like to research coasts?',
    icon: <GiWaveCrest />,
    image: '/episodeImage/HannahCoasts.JPG',
    synopsis: 'In this episode Associate Professor Hannah and I discuss coastal research. We talk about using image recognition to help improve beach safety, trying to understand coastal hazards, including the risk of underwater landslides, and the importance of enjoying your work. hannah shares some of the common myths about the coast and we discuss the importance of accepting coastal change into the future.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Hannah_Coasts.mp3',
    alt: 'cartoon of a person standing next to a rugged coastline',
    guest: {
      name: 'Associate Professor Hannah Power',
      link: 'https://twitter.com/DrHannahPower',
      linkText: 'Follow Hannah\'s work on Twitter',
      description: 'Coastal and Marine Scientist',
    },
    linkList: [
      {
        title: 'Check out Hannah\'s Website here',
        url: 'https://www.hannah-power.com/',
      },
      {
        title: 'Connect with Hannah on LinkedIn',
        url: 'https://www.linkedin.com/in/hannah-power-61917198/',
      },
    ],
  },
  {
    id: '064',
    index: 64,
    date: '2021-11-16',
    name: 'SciCommSeptember Special 2',
    icon: <GiTalk />,
    image: '/episodeImage/SciCommSept.JPG',
    synopsis: 'This is the second of the SciCommSeptember reflection episodes with four wonderful researchers. We discuss some of the ups and downs of being a scientist on Twitter, what a scientist looks like and how we can all encourage each other to share our science more broadly. Tune in for some great reflections and ideas for the future.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_SciCommSept_2.mp3',
    alt: 'cartoon of science related icons around the word SciCommSeptember',
    guest: undefined,
    linkList: [
      {
        title: 'Dr Yow Keat Tham',
        url: 'https://twitter.com/Dr_YKTham',
      },
      {
        title: 'Dr Jessica Buck',
        url: 'https://twitter.com/jessica_l_buck',
      },
      {
        title: 'Dr Maria Rondon',
        url: 'https://twitter.com/MariaRondonG',
      },
      {
        title: 'Dr Celine Santiago',
        url: 'https://twitter.com/celinefsantiago',
      },
    ],
  },
  {
    id: '065',
    index: 65,
    date: '2021-11-24 ',
    name: 'SciCommSeptember Special 3',
    icon: <GiTalk />,
    image: '/episodeImage/SciCommSept.JPG',
    synopsis: 'This is the second of the SciCommSeptember reflection episodes with three wonderful researchers. We discuss some of the benefits of experimenting with different Science Communication platforms, hello TikTok and some of the things that get in our way. We also chat about some ideas for SciComm into the future.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_SciCommSept_3.mp3',
    alt: 'cartoon of science related icons around the word SciCommSeptember',
    guest: undefined,
    linkList: [
      {
        title: 'Dr Melanie Finch',
        url: 'https://twitter.com/melaniefinch_',
      },
      {
        title: 'Dr Jess Hopf',
        url: 'https://twitter.com/JKHopf',
      },
      {
        title: 'Jordyn Thomas',
        url: 'https://twitter.com/JordynMThomas',
      },
    ],
  },
  {
    id: '066',
    index: 66,
    date: '2021-12-02',
    name: 'What\'s it like to be the Chief Scientist of Australia?',
    icon: <GiIdea />,
    image: '/episodeImage/ChiefSci.JPG',
    synopsis: 'In this episode I interview Dr Cathy Foley about her work as Australia\'s Chief Scientist. We discuss some of the project\'s she\'s working on, like making research papers open access to all Australian\'s and helping to inform science based policy at a Federal level. She shares her journey and highlights how important, and challenging, the work that\'s done by civil servants is. Tune in for an inspiring chat!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Chief_Sci.mp3',
    alt: 'cartoon of a person thinking about open access and science based policy',
    guest: {
      name: 'Dr Cathy Foley AO PSM',
      link: 'https://twitter.com/ScienceChiefAu',
      linkText: 'Follow the Chief Scientist\'s work on Twitter',
      description: 'Australia\'s Chief Scientist',
    },
    linkList: [
      {
        title: 'For more about work being undertaken by the Chief Scientist',
        url: 'https://www.chiefscientist.gov.au/',
      },
    ],
  },
  {
    id: '067',
    index: 67,
    date: '2021-12-12',
    name: 'What\'s it like to be a Geologist at the Synchrotron? Family Edition',
    icon: <GiAtom />,
    image: '/episodeImage/EmilyFam.JPG',
    synopsis: 'In this very fun finale for 2021 I talk with Dr Emily and her mum, Krys, about what it\'s like to work as a Geologist at the Synchrotron! Krys shares some wonderful career advice and Emily gives us an insight into some of the things that are happening deep within the Earths Crust, and how the Synchrotron can help us understand them!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Emily_Geol_Fam.mp3',
    alt: 'cartoon of two people standing next to the synchrotron',
    guest: {
      name: 'Dr Emily and Krys',
      link: 'https://twitter.com/DrEmilyFinch',
      linkText: 'Follow Emily\'s work on Twitter',
      description: 'Synchrotron Geologist Researcher',
    },
    linkList: [
      {
        title: 'For more about the Synchrotron',
        url: 'https://www.ansto.gov.au/research/facilities/australian-synchrotron/overview',
      },
      {
        title: 'All about People of Earth Science',
        url: 'https://www.peopleofearthscience.com/',
      },
    ],
  },
  {
    id: '068',
    index: 68,
    date: '2022-01-18',
    name: 'What\'s it like to research informal burials?',
    icon: <GiSpade />,
    image: '/episodeImage/ToriGraves.JPG',
    synopsis: 'For the first episode of 2022 I interview Tori, a PhD candidate in Forensic Anthropology. We talk about finding the right career when you have a desire to help people, and how it\'s possible for graves to be detected without digging!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Tori_Graves.mp3',
    alt: 'cartoon of a person standing next to a grave',
    guest: {
      name: 'Tori Berezowski',
      link: 'https://twitter.com/BerezowskiTori',
      linkText: 'Follow Tori\'s work on Twitter',
      description: 'Forensic Anthropologist',
    },
    linkList: [
      {
        title: 'For more about Dr Tori at Newcastle University',
        url: 'https://www.newcastle.edu.au/highlights/student-highlights/tori-berezowski',
      },
    ],
  },
  {
    id: '069',
    index: 69,
    date: '2022-01-25',
    name: 'What\'s it like to be a Space Environmentalist?',
    icon: <GiSpaceShuttle />,
    image: '/episodeImage/Doris_SpaceJunk.JPG',
    synopsis: 'In this episode I interview Doris about her work as an optics engineer and space environmentalist. We talk about some of the challenges facing Earth\'s orbit, including debris and their potential impacts. We also talk about the importance of sattelites and space technology.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Doris_SpaceJunk.mp3',
    alt: 'cartoon of a person standing next to a telescope pointing at space junk in the sky',
    guest: {
      name: 'Dr Doris Grosse',
      link: 'https://researchers.anu.edu.au/researchers/grosse-d',
      linkText: 'Learn more about Dr Doris\'s work',
      description: 'Optics Research Fellow',
    },
    linkList: [
      {
        title: 'Dr Doris\'s profile on STEM Women',
        url: 'https://www.stemwomen.org.au/profile/doris-grosse',
      },
    ],
  },
  {
    id: '070',
    index: 70,
    date: '2022-02-01',
    name: 'What\'s it like to professionally wrangle worms?',
    icon: <GiWorms />,
    image: '/episodeImage/YeeLianWorms.JPG',
    synopsis: 'This episode is a wonderful interview with Dr Yee Lian, affectionately known as worm woman! We talk about some of the wonders of the worm and her research into understanding how a worm makes memories. Yee Lian is candid about some of the challenges facing scientists hunting for jobs, and speaks with hope about how science can be used to change the world.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_YeeLian_Worms.mp3',
    alt: 'cartoon of a person standing next to a lab bench thinking about worms',
    guest: {
      name: 'Dr Yee Lian Chew',
      link: 'https://twitter.com/wormychew',
      linkText: 'Follow Yee Lian\'s work on Twitter',
      description: 'Nematode Neuroscience',
    },
    linkList: [
      {
        title: 'For more about the EMCR Forum',
        url: 'https://www.science.org.au/supporting-science/early-and-mid-career-researchers-0',
      },
      {
        title: 'For all about the Chew Worm lab click here',
        url: 'https://chewwormlab.wordpress.com/',
      },

    ],
  },
  {
    id: '071',
    index: 71,
    date: '2022-02-08',
    name: 'What\'s it like to research adaptive materials Engineering?',
    icon: <FaBuilding />,
    image: '/episodeImage/TahaEng.JPG',
    synopsis: 'In this episode Dr Taha and I talk about a range of things, from adaptive materials research to thinking creatively about research to come up with novel ways to limit energy loss from buildings. Taha shares some wonderful insights into genuine diversity, inclusion and acceptance and the importance of making STEM less hostile!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Taha_Eng.mp3',
    alt: 'cartoon of a person standing next to a building wearing sunglasses under a hot sun',
    guest: {
      name: 'Dr Mohammad Taha',
      link: 'https://twitter.com/TahaSciencing',
      linkText: 'Follow Dr Taha\'s work on Twitter',
      description: 'Materials Engineer',
    },
    linkList: [
      {
        title: 'For more about Taha\'s thoughts, works and experiments head over here',
        url: 'https://www.tahasciencing.com/',
      },
      {
        title: 'For more about the EMCR Forum',
        url: 'https://www.science.org.au/supporting-science/early-and-mid-career-researchers-0',
      },
    ],
  },
  {
    id: '072',
    index: 72,
    date: '2022-02-16',
    name: 'What\'s it like to research childhood brain cancer?',
    icon: <GiMedicines />,
    image: '/episodeImage/JessicaBrainCancer.JPG',
    synopsis: 'In this episode I have a wonderful chat with Dr Jessica, a Childhood Brain Cancer researcher. We talk about drug screening robots, the challenge of working with the blood brain barrier. Jessica shares her story of how she got into medical research and her love for making meaningful change with her research!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Jessica_Cancer.mp3',
    alt: 'cartoon of a person thinking about brains',
    guest: {
      name: 'Dr Jessica Buck',
      link: 'https://twitter.com/jessica_l_buck',
      linkText: 'Follow Jessica\'s work on Twitter',
      description: 'Childhood Brain Cancer Researcher',
    },
    linkList: [
      {
        title: 'For more about the Brainbow, check out some of the amazing photos',
        url: 'https://en.wikipedia.org/wiki/Brainbow',
      },
    ],
  },
  {
    id: '073',
    index: 73,
    date: '2022-02-23',
    name: 'What\'s it like to research decarbonisation with liquid metals?',
    icon: <AiFillCloud />,
    image: '/episodeImage/Torben_Eng.JPG',
    synopsis: 'In this episode I talk with Dr Torben about some very awesome research into decarbonisation using liquid metals. We discuss how some of the coolest inventions come from play, and the intersection between two fields of knowledge. We also talk about the importance of interviewing the people who will be managing you, especially if you\'re thinking about doing a PhD',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Torben_Chem.mp3',
    alt: 'Cartoon of a person standing next to a blob of carbon with co2 in the air',
    guest: {
      name: 'Dr Torben Daeneke',
      link: 'https://www.rmit.edu.au/contact/staff-contacts/academic-staff/d/daeneke-torben',
      linkText: 'For more about Torben\'s research',
      description: 'Inorganic Chemist',
    },
    linkList: [
      {
        title: 'For more about decarbonisation check out this media release',
        url: 'https://www.rmit.edu.au/news/media-releases-and-expert-comments/2022/jan/decarbonisation-tech',
      },

    ],
  },
  {
    id: '074',
    index: 74,
    date: '2022-03-01',
    name: 'What\'s it like to work in the space industry? Law and Education Edition',
    icon: <GiSpaceShuttle />,
    image: '/episodeImage/KimSpaceLaw.JPG',
    synopsis: 'In this week\'s episode I talk with Kim about what it\'s like to work at the intersection of Law and Space. We talk about some of the opportunities that are coming with an expanding space industry, and the power of being asked about what you really want to do with your career.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_KimSpace.mp3',
    alt: 'cartoon of someone with a clipboard thinking about space',
    guest: {
      name: 'Kim Ellis Hayes',
      link: 'https://www.linkedin.com/in/kimgellis/',
      linkText: 'Connect with Kim on LinkedIn',
      description: 'Space Technology Program Director',
    },
    linkList: [
      {
        title: 'For more about the Swinburne Space Technology Program',
        url: 'https://www.swinburne.edu.au/research/institutes/space-technology-industry/',
      },
      {
        title: 'For all about the International Space University',
        url: 'https://www.isunet.edu/',
      },
    ],
  },
  {
    id: '075',
    index: 75,
    date: '2022-03-08',
    name: 'What\'s it like to be a plant ecologist and conservationist?',
    icon: <GiForest />,
    image: '/episodeImage/RachaelEcology.JPG',
    synopsis: 'This week Rachael and I discuss what it\'s like to be a plant ecologist working to understand the impact of global changes on plant health, distribution and range. We talk about some of the research Rachael is doing, and the challenges facing our ecosystems. We also talk about studying the arts before studying science and bust some great myths!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Rachael_Plants.mp3',
    alt: 'cartoon of someone standing with lots of trees around them',
    guest: {
      name: 'Associate Professor Rachael Gallagher',
      link: 'https://twitter.com/ecolo_gist?lang=en',
      linkText: 'Follow Rachael on Twitter',
      description: 'Plant Ecologist',
    },
    linkList: [
      {
        title: 'To learn all about the ARC Centre of Excellence for Plant Success in Nature and Agriculture',
        url: 'https://www.plantsuccess.org/',
      },
      {
        title: 'For more about the Hawkesbury Institute for the Environment',
        url: 'http://westernsydney.edu.au/hie',
      },
    ],
  },
  {
    id: '076',
    index: 76,
    date: '2022-03-15',
    name: 'What\'s it like to work towards diversity in tech?',
    icon: <FaLaptopCode />,
    image: '/episodeImage/GretchenTech.JPG',
    synopsis: 'This is a great chat with Gretchen about life in tech. We talk about some of the reasons underrepresented groups aren\'t staying in tech at mid career and how being inclusive first can be a great start. We also talk about the value of a PhD outside academia!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Gretchen.mp3',
    alt: 'cartoon of someone thinking about talking and technology',
    guest: {
      name: 'Gretchen Scott',
      link: 'https://twitter.com/scott_gretchen',
      linkText: 'Follow Gretchen on Twitter',
      description: 'Human Collective Founder',
    },
    linkList: [
      {
        title: 'For more about The Human Collective head over to their website',
        url: 'https://www.thehumancollective.com.au/',
      },
      {
        title: 'Check out some of the great courses from Code like a Girl',
        url: 'https://www.codelikeagirl.com/',
      },
    ],
  },
  {
    id: '077',
    index: 77,
    date: '2022-03-30',
    name: 'What\'s it like to be a Statistician?',
    icon: <FaPercent />,
    image: '/episodeImage/NicoleStats.JPG',
    synopsis: 'In this episode I chat with Dr Nicole about life as a Statistician. We talk about using maths as a tool to answer questions, thinking critically about data, and how we can improve the health care system through statistics! We also talk about the importance of career pathway visibility.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Nicole_Stats.mp3',
    alt: 'cartoon of someone thinking maths and looking at a line chart',
    guest: {
      name: 'Dr Nicole White',
      link: 'https://twitter.com/nicolem_white',
      linkText: 'Follow Nicole on Twitter',
      description: 'Statistician',
    },
    linkList: [
      {
        title: 'Have a look at some of Nicole\'s research',
        url: 'https://scholar.google.com/citations?user=HunlE1AAAAAJ&hl=en',
      },
    ],
  },
  {
    id: '078',
    index: 78,
    date: '2022-04-06',
    name: 'What\'s it like to be a full stack web developer?',
    icon: <FaLaptopCode />,
    image: '/episodeImage/LindaTech.JPG',
    synopsis: 'This is a wonderful chat with Linda about all things related to careers in tech. We talk about what web development actually involves, some of the harmful stereotypes and some of the opportunities available to people in this career. We also discuss the importance of making the web accessible to all.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Linda_Tech.mp3',
    alt: 'cartoon of someone with a laptop looking at code',
    guest: {
      name: 'Linda Lai',
      link: 'https://www.linkedin.com/in/lindalai/',
      linkText: 'Connect with Linda on LinkedIn',
      description: 'Full Stack Web Developer',
    },
    linkList: [
      {
        title: 'Your head is a Houseboat Book',
        url: 'https://www.booktopia.com.au/your-head-is-a-houseboat-campbell-walker/book/9781743797495.html',
      },
      {
        title: 'Join The Human Collective Slack channel where we chat tech',
        url: 'https://www.thehumancollective.com.au/',
      },
      {
        title: 'The Code Newbie Podcast',
        url: 'https://www.codenewbie.org/',
      },
    ],
  },
  {
    id: '079',
    index: 79,
    date: '2022-04-19',
    name: 'What\'s it like to be a Computational Biologist',
    icon: <FaCodeBranch />,
    image: '/episodeImage/AnyaAllergens.JPG',
    synopsis: 'In this episode I have a chat with Dr Anya about what it\'s like to work at the intersection of data analysis and biology. We talk about learning how to code, and teaching yourself. The importance of data quality and the excitement of networking with other women in STEM!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Anya_Allergens.mp3',
    alt: 'cartoon of someone with a laptop looking at code',
    guest: {
      name: 'Dr Anya Jones',
      link: 'https://twitter.com/dranyajones',
      linkText: 'Follow Dr Anya on Twitter',
      description: 'Computational Biologist',
    },
    linkList: [
      {
        title: 'For more about Dr Anyas work check out Telethon Kids',
        url: 'https://www.telethonkids.org.au/contact-us/our-people/j/anya-jones/',
      },
    ],
  },
  {
    id: '080',
    index: 80,
    date: '2022-04-24',
    name: 'What\'s coming up in May?',
    icon: <GiTalk />,
    image: '/episodeImage/MSM_Intro_1.JPG',
    synopsis: 'Coming your way this May! The team behind Let\'s talk SciComm and us at Avid Reearch have combined our powers to create a social media challenge focussed around collaboration and networking. Check out the prompts and get involved!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_MyScienceMay.mp3',
    alt: 'a cartoon speech bubble that says hashtag my science may, a month of connection and collaboration',
    guest: undefined,
    linkList: [
      {
        title: 'Head on over to the My Science May website for all the prompts',
        url: 'https://www.mysciencemay.com/',
      },
      {
        title: 'Check out the Lets talk SciComm podcast',
        url: 'https://anchor.fm/letstalkscicomm',
      },
    ],
  },
  {
    id: '081',
    index: 81,
    date: '2022-05-05',
    name: 'What\'s it like to enable inventors?',
    icon: <FaLightbulb />,
    image: '/episodeImage/Carmen_Inventions.JPG',
    synopsis: 'In this episode I have a great chat with Carmen who is a wonderful enabler of inventors. We talk about life as a technical business consultant, the way problems can lead to ideas, and navigating winding journeys in life!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Carmen_Inventions.mp3',
    alt: 'Cartoon of someone thinking about talking, ideas with a lightbulb and patents',
    guest: {
      name: 'Carmen Munian',
      link: 'https://www.linkedin.com/in/carmen-munian-32b86157/',
      linkText: 'Connect with Carmen on LinkedIn',
      description: 'Technical Business Consultant',
    },
    linkList: [
      {
        title: 'For more about what Carmen does, head on over to her website',
        url: 'https://www.munianconsulting.com/',
      },
    ],
  },
  {
    id: '082',
    index: 82,
    date: '2022-05-09',
    name: 'Some thoughts on questions and Avid Research plans!',
    icon: <GiTalk />,
    image: '/episodeImage/ARUpdated_2022.JPG',
    synopsis: 'A short chat from me about some plans for Avid Research, including planning in holidays that match the Victorian School Holidays! And some reflections on the wonderful conversations in MyScienceMay and practicing saying no!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_MyScienceMayRamble.mp3',
    alt: 'cartoon of a thought bubble with the hashtag MyScienceMay, holidays, stem and questions inside',
    guest: undefined,
    linkList: [
      {
        title: 'For more about MyScienceMay',
        url: 'https://www.mysciencemay.com/',
      },
    ],
  },
  {
    id: '083',
    index: 83,
    date: '2022-05-18',
    name: 'What\'s it like to be a Geology Lab Manager?',
    icon: <FaMicroscope />,
    image: '/episodeImage/YonaGeolTech.JPG',
    synopsis: 'In this episode I have a great chat with Dr Yona about what it\'s like to manage a Geology lab. We talk about her path to where she is now, including a bit of time spent teaching young children! We also talk about how important being involved in science is for her in her career!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Yona_MassSpec.mp3',
    alt: 'Cartoon person standing infront of a lab thinking about rocks',
    guest: {
      name: 'Dr Yona Nebel-Jacobsen',
      link: 'https://twitter.com/Isotope_Yona',
      linkText: 'Follow Yona on Twitter',
      description: 'Senior Research Manager and Lab Manager',
    },
    linkList: [
      {
        title: 'For more about Dr Yona have a look at her STEM women profile!',
        url: 'https://www.stemwomen.org.au/profile/yona-nebel-jacobsen',
      },
    ],
  },
  {
    id: '084',
    index: 84,
    date: '2022-04-24',
    name: 'What\'s it like to be a Sound Technician?',
    icon: <GiSoundWaves />,
    image: '/episodeImage/CoreySound.JPG',
    synopsis: 'In this chat I talk with Corey, a sound technician and author of a wonderful book that will help you get into podcasting. We talk about the amazingness of technology, the power of connection through the internet, and the importance of getting sound right when it comes to your podcast!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Corey_Sound.mp3',
    alt: 'cartoon of someone with a laptop thinking about music and a sine wave',
    guest: {
      name: 'Corey Green',
      link: 'https://twitter.com/AudioTransducer',
      linkText: 'Follow Corey on Twitter',
      description: 'Sound Technician',
    },
    linkList: [
      {
        title: 'For all things sound Tech, including how to get your hands on the book, head to Corey\'s website',
        url: 'https://www.transducer-audio.com/',
      },
    ],
  },
  {
    id: '085',
    index: 85,
    date: '2022-06-07',
    name: 'What\'s it like to be an adventurous Park Ranger?',
    icon: <FaMountain />,
    image: '/episodeImage/AmandaGreenLine.JPG',
    synopsis: 'This was a great chat with Ranger Amanda about her career, from chef to park ranger! And about her upcoming adventure Walking the Thin Green Line working to highlight some of the amazing protected areas in Oceania and the rangers who protect it.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Amanda_GreenLine.mp3',
    alt: 'cartoon of a person in a hat with a camera standing next to some mountains and water',
    guest: {
      name: 'Amanda Dudgeon',
      link: 'https://www.instagram.com/walkingthethingreenline/',
      linkText: 'Follow Amanda on Instagram',
      description: 'Park Ranger',
    },
    linkList: [
      {
        title: 'To learn more about Amanda\'s trip and to follow along check out the Walking the thin green line website',
        url: 'https://www.walkingthethingreenline.com/',
      },
      {
        title: 'For all the links check out Amanda\'s linktree',
        url: 'https://linktr.ee/walkingthethingreenline',
      },
    ],
  },
  {
    id: '086',
    index: 86,
    date: '2022-06-14',
    name: 'What\'s it like to match-make industry and researchers?',
    icon: <FaAtom />,
    image: '/episodeImage/Megan_CSIRO.JPG',
    synopsis: 'In this interview I chat with Dr Megan from the CSIRO\'s Kick-Start Program where her job is to help businesses and startups in Australia connect with CSIRO\'s research expertise. We discuss the importance of diversity in STEM, solving problems and some of the exciting innovations happening in the Australian landscape.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Megan_CSIRO.mp3',
    alt: 'cartoon of someone with a laptop next to speech bubbles and a thought bubble with a lighbulb in it',
    guest: {
      name: 'Dr Megan Sebben',
      link: 'https://twitter.com/DrMegsSebben',
      linkText: 'Follow Megan on Twitter',
      description: 'Kick-Start Program Manager',
    },
    linkList: [
      {
        title: 'To learn more about the Kick-Start program head on over to CSIRO\'s website',
        url: 'https://www.csiro.au/en/work-with-us/funding-programs/SME/CSIRO-Kick-Start/about',
      },
    ],
  },
  {
    id: '087',
    index: 87,
    date: '2022-06-22',
    name: 'What\'s it like to research an underwater paradox from a ship?',
    icon: <FaShip />,
    image: '/episodeImage/HannahInvestigator.JPG',
    synopsis: 'For something quite different! I had a great chat with six people currently at sea on the RV Investigator. We talk about what the purprose of their trip is, the challenges and excitement of deep sea research and what scientific problem they\'re trying to solve. They introduced me to a very curious paradox!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_HannahInvestigator.mp3',
    alt: 'Cartoon of six stick figures standing on a ship with soundwaves coming out underneath',
    guest: undefined,
    linkList: [
      {
        title: 'To learn more about the RV Investigator click here',
        url: 'https://mnf.csiro.au/en/RV-Investigator',
      },
      {
        title: 'To follow A/Prof Hannah\'s daily shipboard updates check out her Twitter',
        url: 'https://twitter.com/DrHannahPower',
      },
    ],
  },
  {
    id: '088',
    index: 88,
    date: '2022-06-28',
    name: 'What\'s it like to be Geologists? Portugese and Italian version',
    icon: <FaGlobeAsia />,
    image: '/episodeImage/VitorSilviaGeol.JPG',
    synopsis: 'The first multilingual Avid Research episode! With a wonderful chat about what it\'s like to be a Geologist, featuring Dr Vitor, translating to Portugese, and Dr Silvia, translating to Italian. We talk about Geology careers and the challenges and opportunities of doing STEM in languages other than English. Tune in for a very fun chat in three languages!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Vitor_Silvia.mp3',
    alt: 'cartoon of two people standing under a globe',
    guest: undefined,
    linkList: [
      {
        title: 'Follow Vitor on Twitter',
        url: 'https://twitter.com/GeoDrB',
      },
      {
        title: 'Follow Silvia on Twitter',
        url: 'https://twitter.com/silvia_volante',
      },
      {
        title: 'Tune in to Gneiss Chats',
        url: 'https://www.travelinggeologist.com/geology-podcast-network/',
      },
    ],
  },
  {
    id: '089',
    index: 89,
    date: '2022-07-05',
    name: 'What\'s it like to do a PhD in cardiovascular research?',
    icon: <FaHeartbeat />,
    image: '/episodeImage/GayarthryCVD.JPG',
    synopsis: 'In this episode Gayathry and I discuss what it\'s like to be a PhD student. We also talk about her research into how pregnancy changes the body and how that can impact people\'s health into the future!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_GayarthryCVD.mp3',
    alt: 'Cartoon of a person thinking about hearts',
    guest: {
      name: 'Gayathry Krishnamurthy',
      link: 'https://twitter.com/GayathryKrishn5',
      linkText: 'Follow Gayathry on Twitter',
      description: 'PhD Candidate',
    },
    linkList: [
      {
        title: 'Learn more about Menzies at UTAS',
        url: 'https://www.menzies.utas.edu.au/',
      },
    ],
  },
  {
    id: '090',
    index: 90,
    date: '2022-07-12',
    name: 'What\'s it like to use statistics to research coral reef changes?',
    icon: <GiClownfish />,
    image: '/episodeImage/JulieReefs.JPG',
    synopsis: 'In this episode I chat with Dr Julie about her career as a statistician investigating the Great Barrier Reef. We talk about the challenges of getting data for such a diverse area and the changes that will be impacting the reef into the future. Dr Julie shares some of her insights into citizen science, AI and combining skill sets!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Julie_StatsReef.mp3',
    alt: 'Cartoon of a person holding a laptop, thinking about mathematical symbols and thinking about the ocean',
    guest: {
      name: 'Dr Julie Vercelloni',
      link: 'https://twitter.com/JVercelloni',
      linkText: 'Follow Julie on Twitter',
      description: 'Statistical Researcher',
    },
    linkList: [
      {
        title: 'For more about Dr Julie\'s reef research click here',
        url: 'https://research.qut.edu.au/reefresearch/',
      },
      {
        title: 'Check out the Virtual Reef Diver site',
        url: 'https://www.virtualreef.org.au/',
      },
    ],
  },
  {
    id: '091',
    index: 91,
    date: '2022-07-19',
    name: 'What is it like to run a STEM mentoring initative?',
    icon: <FaMicrophoneAlt />,
    image: '/episodeImage/SidBrainStem.JPG',
    synopsis: 'In this episode I interview Sid, founder of BrainSTEM and advisor of mentors. We talk about the importance and potential of mentoring, and how to develop good mentoring relationships. We also discuss the importance of diversity in STEM!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Sid_STEM.mp3',
    alt: 'Cartoon of a person with four speech bubbles next to them',
    guest: {
      name: 'Sid Verma',
      link: 'https://twitter.com/sid_verma',
      linkText: 'Follow Sid on Twitter',
      description: 'Founder',
    },
    linkList: [
      {
        title: 'Learn more about BrainSTEM here',
        url: 'http://brainstem.org.au/index.html',
      },
    ],
  },
  {
    id: '092',
    index: 92,
    date: '2022-07-26',
    name: 'What is it like to research ecology with drones?',
    icon: <GiDeliveryDrone />,
    image: '/episodeImage/RebeccaDrones.JPG',
    synopsis: 'This episode is a wonderful chat with Dr Rebecca who is an expert at solving problems and using technology to understand ecology. We talk about the importance of taking opportunities as they come, and keeping a positive mindset when facing challenges!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Rebecca_Drones.mp3',
    alt: 'Cartoon of a stick figure with a remote control and a quadcopter above them',
    guest: {
      name: 'Dr. Rebecca Rogers',
      link: 'https://twitter.com/AFlyingKiwiBird',
      linkText: 'Follow Rebecca on Twitter',
      description: 'Drone Pilot and Researcher',
    },
    linkList: [
      {
        title: 'Learn more about drone school at Charles Darwin University',
        url: 'https://www.cdu.edu.au/newsroom/drones',
      },
    ],
  },
  {
    id: '093',
    index: 93,
    date: '2022-06-28',
    name: 'What is it like to optimise insect nutrition?',
    icon: <AiFillBug />,
    image: '/episodeImage/Sofia_Insects.JPG',
    synopsis: 'This is a wonderful chat with Julia about her pathway to becoming a nutritionist for insects. We talk about what black solider flies really love to eat, the importance of texture in their diet and how they might be able to help us with environmental problems. We also talk about creating opportunities for yourself and the value of finding problems that you are driven to help solve.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Sofia_insects.mp3',
    alt: 'Stick figure thinking about a bug lifecycle, egg to worm to fly with some carrots',
    guest: {
      name: 'Sofia Katzin',
      link: 'https://twitter.com/SofiaKatzin',
      linkText: 'Follow Sofia on Twitter',
      description: 'Insect Nutritionist',
    },
    linkList: [
      {
        title: 'Learn more about Future Green Solutions',
        url: 'https://www.futuregreensolutions.com.au/',
      },
    ],
  },
  {
    id: '094',
    index: 94,
    date: '2022-08-10',
    name: 'What is it like to run Shirty Science?',
    icon: <FaTshirt />,
    image: '/episodeImage/Madi_ShirtySci.JPG',
    synopsis: 'This is a wonderful chat with Madi, the founder of Shirty Science! We talk about how Shirty Science came to be, what has insipired it, and how it\'s being taken to new levels this year. Madi shares some insights into working with artists and scientists, and her hopes for the program this year',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Madi_ShirtySci.mp3',
    alt: 'Stick figure thinking about a tshirt with a question mark on it',
    guest: {
      name: 'Madi Hartill-Law',
      link: 'https://twitter.com/madi_hartilllaw',
      linkText: 'Follow Madi on Twitter',
      description: 'Shirty Science Founder',
    },
    linkList: [
      {
        title: 'For all things Shirty Science check out the website',
        url: 'https://www.shirtyscience.com/',
      },
    ],
  },
  {
    id: '095',
    index: 95,
    date: '2022-08-18',
    name: 'Creating "Galactic Bloom" - Shirty Science Edition',
    icon: <FaTshirt />,
    image: '/episodeImage/GalacticBloom.JPG',
    synopsis: 'This is a wonderful chat with Dr Natasha Hurley-Walker and Bec Adamczewski about their creation of the Galactic Bloom design as part of the 2022 Shirty Science season. We talk about visualising research, collaboration and the importance of connecting where we are with what we are looking at. This was also voted Australia\'s favourite science tshirt!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_shirtySci1.mp3',
    alt: 'black mock up of a tshirt with red to blue flower like stars reflecting the galaxy',
    guest: undefined,
    linkList: [
      {
        title: 'To buy the Galactic Bloom Shirt head to Shirty Science',
        url: 'https://www.shirtyscience.com/shirts/galactic-blooms',
      },
      {
        title: 'Follow Dr Natasha on Twitter',
        url: 'https://twitter.com/ColourfulCosmos',
      },
      {
        title: 'Follow Bec on Instagram',
        url: ' https://www.instagram.com/becskidesign',
      },
    ],
  },
  {
    id: '096',
    index: 96,
    date: '2022-08-25',
    name: 'What is it like to help businesses implement AI and ML?',
    icon: <GiBrain />,
    image: '/episodeImage/jessieAIML.JPG',
    synopsis: 'This is a wonderful chat with Jessie-Lee about her career into business development with AI and ML. We talk about ruthless prioritisation and how accessible jobs in AI and ML actually are. Jessie also shares some of the ways that ML is currently being used and gives some wonderful career advice.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_JessieAIML.mp3',
    alt: 'A cartoon of a stick figure holding a laptop and thinking about a cloud that says AI/ML',
    guest: {
      name: 'Jessie-Lee Fry',
      link: 'https://www.linkedin.com/in/jessie-lee-f-b3727622/',
      linkText: 'Connect with Jesse on LinkedIn',
      description: 'Global AI/ML BD Lead',
    },
    linkList: [
      {
        title: 'For all things Amazon Web Services check out their website',
        url: 'https://aws.amazon.com/',
      },
    ],
  },
  {
    id: '097',
    index: 97,
    date: '2022-08-30',
    name: 'Creating "Worming in Memories" - Shirty Science Edition',
    icon: <FaTshirt />,
    image: '/episodeImage/ShirtySci2.JPG',
    synopsis: 'In another Shirty Science special I interviewed Rachel and Anna about their creation, Worming in Memories. We talked about the artistic process, learning about communication and learned some amazing facts about worm brains and their memories!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_ShirtyScience2.mp3',
    alt: 'A picture of a tshirt with a double head on it and rods being held above the heads with worms',
    guest: undefined,
    linkList: [
      {
        title: 'To buy the Worming in Memories Shirt head to Shirty Science',
        url: 'https://www.shirtyscience.com/shirts/worming-in-memories',
      },
      {
        title: 'Follow Rachel on Instagram',
        url: 'https://www.instagram.com/pencilpusherpix/',
      },
    ],
  },
  {
    id: '098',
    index: 98,
    date: '2022-09-07',
    name: 'What is it like to be a Senior Delivery Lead in Software Testing?',
    icon: <GiSpeedometer />,
    image: '/episodeImage/MicheleAgile.JPG',
    synopsis: 'In this episode I interview Michele, a Senior Delivery Lead at Culture Amp. We talk about Agile, the importance of continuous improvement and the challenges of getting software well tested. Michele shares some insights into her career and how she has brought together such a range of skills into her current job.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Michele_DL.mp3',
    alt: 'A cartoon of a stick figure with two thought bubbles filled with people talking',
    guest: {
      name: 'Michele Playfair',
      link: 'https://twitter.com/MichelePlayfair',
      linkText: 'Follow Michele on Twitter',
      description: 'Senior Delivery Lead',
    },
    linkList: [
      {
        title: 'Connect with Michele on LinkedIn',
        url: 'https://www.linkedin.com/in/micheleplayfair/',
      },
    ],
  },
  {
    id: '099',
    index: 99,
    date: '2022-09-21',
    name: 'Why is it so hard to do a dual language podcast about STEM?',
    icon: <GiSoundWaves />,
    image: '/episodeImage/CelineLanguage.JPG',
    synopsis: 'This is a wonderful chat with Celine about why it\'s quite hard to do a dual language podcast. Some notes and corrections about the episode are below. An additional comment from Dr. Celine about publishing scientific articles: Generally speaking, the journals with largest audience are typically published in English. After speaking to friends and colleagues working in countries where English is not the national language, it seems that scientists and academics are simply expected to read and write English fluently, and submit their articles in English. Smaller, national journals may be published in languages other than English.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Celine_Languages.mp3',
    alt: 'cartoon of someone thinking about talking and a zebra fish',
    guest: {
      name: 'Dr Celine Santiago',
      link: 'https://twitter.com/celinefsantiago',
      linkText: 'Follow Celine on Twitter',
      description: 'Cardiovascular Researcher',
    },
    linkList: [
      {
        title: 'Tagalog is influenced heavily by Spanish and Malay, not specifically Indonesian',
        url: '',
      },
      {
        title: 'The Philippines was actually colonised by Spain for 3 centuries. The Americans freed the Philippines from Spanish colonisation, but then they colonised the Philippines for 48 years',
        url: '',
      },
      {
        title: 'The word for "health" in Tagalog is "kalusugan" so "heart health" could be translated into "kalusugan ng puso", health of the heart',
        url: '',
      },
      {
        title: 'A comment from Dr. Celine\'s mum, who is fluent in Tagalog, Bicolano, a dialect of the Philippines, and English: “It\'s true that many regional dialects are spoken in the Philippines and it\'s likely that a dialect would be spoken over tagalog, and they might not speak english in rural communities, but it\'s also true that they would know tagalog quite well, since it\'s the national language, so concepts about heart health/research could be conveyed in tagalog…a lot of scientific concepts would actually be easier to explain in English rather than trying to translate it into Tagalog (filipino). The translations would be clunky and awkward and make it hard for people to understand. So just saying the English terms but spelling it in Tagalog would make things a lot easier to express and make understandable',
        url: '',
      },
      {
        title: 'To learn more about how languages around the world are regulated check out this wikipedia page',
        url: 'https://en.wikipedia.org/wiki/List_of_language_regulators',
      },
      {
        title: 'This paper on how languages are regulated',
        url: 'https://www.trustedtranslations.com/blog/worlds-languages-regulated',
      },
      {
        title: 'And this article on the regulation of language',
        url: 'https://www.journals.uchicago.edu/doi/full/10.1086/699005',
      },
      {
        title: 'And an interesting Scientific American article on the translation of science',
        url: 'https://blogs.scientificamerican.com/observations/the-art-of-translating-science/',
      },
    ],
  },
  {
    id: '100',
    index: 100,
    date: '2022-09-28',
    name: 'What\'s it like to coach high performance cloud teams?',
    icon: <GiCloudDownload />,
    image: '/episodeImage/GwennySRE.JPG',
    synopsis: 'For the 100th episode of Avid Research I have a great chat with Gwenny about changing careers into tech and cloud computing. We talk about how the skills in tech are learnable and what the cloud actually is!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_GwennySRE.mp3',
    alt: 'A cartoon of a stick figure holding a laptop and thinking about a cloud',
    guest: {
      name: 'Gwenny Warnick',
      link: 'https://www.linkedin.com/in/gwendolen-warnick/',
      linkText: 'Connect with Gwenny on LinkedIn',
      description: 'Principal Engineer',
    },
    linkList: [
      {
        title: 'For more about SRE check out these great books',
        url: 'https://sre.google/books/',
      },
    ],
  },
  {
    id: '101',
    index: 101,
    date: '2022-10-25',
    name: 'Reflections on a trip from Darwin to Broome',
    icon: <AiOutlineCompass />,
    image: '/episodeImage/DarwinToBroome.JPG',
    synopsis: 'A rambling chat and reflection on joining Ranger Amanda in Walking the Thin Green Line between Darwin to Broome. ',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_TravelReflection.mp3',
    alt: 'A cartoon of a map of australia with a curvy line drawn from Darwin to Broome',
    guest: undefined,
    linkList: [
      {
        title: 'For more about Walking the Thin Green Line',
        url: 'https://www.walkingthethingreenline.com/',
      },
    ],
  },
  {
    id: '102',
    index: 102,
    date: '2022-11-03',
    name: 'Creating Heart Disease Prevention Shirty Science edition',
    icon: <GiHeartBeats />,
    image: '/episodeImage/ShirtySci4CVD.JPG',
    synopsis: 'This is a wonderful chat with Dr Hamdi and Janet about the process behind the creation of the Heart Disease Prevention shirt. They talk about the process of collaboration and some of the amazing research that is behind this whimsical design.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Shirty4.mp3',
    alt: 'A picture of a white tshirt with a heart and cruits and vegetables on a tshirt',
    guest: undefined,
    linkList: [
      {
        title: 'Buy the Heart Disease Prevention tshirt through the Shirty Science website here',
        url: 'https://www.shirtyscience.com/shirts/heart-disease-prevention',
      },
      {
        title: 'Have a look at Janets website and amazing art here',
        url: 'https://www.janetparker-smith.com.au/',
      },
    ],
  },
  {
    id: '103',
    index: 103,
    date: '2022-11-16',
    name: 'What\'s it like to be a Chief Technology Officer?',
    icon: <GiTalk />,
    image: '/episodeImage/ScarlettCTO.JPG',
    synopsis: 'This was a wonderful inspiring chat with Scarlett the CTO of WithYouWithMe, a social impact company helping underrepresented groups transfer into tech. She talks about her career journey, some of the challenges she\'s faced and the opportunities she\'s taken.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_ScarlettCTO.mp3',
    alt: 'Cartoon of a person holding a laptop talking and thinking with a light bulb',
    guest: {
      name: 'Scarlett McDermott',
      link: 'https://www.linkedin.com/in/scarlett-mcdermott-089a01190/',
      linkText: 'Connect with Scarlett on LinkedIn',
      description: 'CTO',
    },
    linkList: [
      {
        title: 'For more about WithYouWithMe check out their website!',
        url: 'https://withyouwithme.com/',
      },
    ],
  },
  {
    id: '104',
    index: 104,
    date: '2022-11-16',
    name: 'What\'s it like to be a Cyber Security Analyst?',
    icon: <GiShield />,
    image: '/episodeImage/CarlyWYWM.JPG',
    synopsis: 'This was a wonderful chat with WithYouWithMe participant Carly about her transition from admin into tech. She shares her story of trying different kinds of tech roles and some of the things that have driven her to succeed in her new career. Carly shares some insights into the challenges of being a military spouse and the opportunities that can come with remote work!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Carly_WYWM.mp3',
    alt: 'Cartoon of a person holding a laptop talking and a shield',
    guest: {
      name: 'Carly Owens',
      link: 'https://www.linkedin.com/in/carly-owens1/',
      linkText: 'Connect with Carly on LinkedIn',
      description: 'Cyber Security Analyst',
    },
    linkList: [
      {
        title: 'For more about WithYouWithMe check out their website!',
        url: 'https://withyouwithme.com/',
      },
    ],
  },
  {
    id: '105',
    index: 105,
    date: '2023-01-30',
    name: 'What\'s it like to write software for telescopes?',
    icon: <FaSatelliteDish />,
    image: '/episodeImage/Mars_Code.JPG',
    synopsis: 'Starting the year with a wonderful chat with Mars Buttfield-Addison about her work coding programs for telescopes to help them detect things they haven\'t been made to detect! ⁠We talk about the reuse of data, creating our own jobs and how different university is to school! ⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Mars_Code.mp3',
    alt: 'Cartoon of a person holding a laptop having an idea with a satellite dish',
    guest: {
      name: 'Mars Buttfield-Addison',
      link: 'https://twitter.com/themartianlife?lang=en',
      linkText: 'Follow Mars on Twitter',
      description: 'Computer Scientist',
    },
    linkList: [
      {
        title: 'Check out Mars\' website for more about her work',
        url: 'https://themartianlife.com/',
      },
    ],
  },
  {
    id: '106',
    index: 106,
    date: '2023-01-30',
    name: 'What\'s it like to research Heart Health and Lipids?',
    icon: <FaHeartbeat />,
    image: '/episodeImage/YowKeatBaker.JPG',
    synopsis: 'In this episode Dr Yow Keat and I chat about his role as a cardiovascular researcher and enjoying the transition away from the lab and into deeper analysis of results. We talk about the importance of failure and finding new paths forward, and about not getting dispirited about negative results. We even throw in a bit of arts and science!⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_YowKeat_Baker.mp3',
    alt: 'Cartoon of a person holding a laptop thinking about hearts',
    guest: {
      name: 'Dr Yow Keat Tham',
      link: 'https://twitter.com/Dr_YKTham',
      linkText: 'Follow Yow Keat on Twitter',
      description: 'Cardiovascular Researcher',
    },
    linkList: [
      {
        title: 'You can also follow Dr Yow Keat on Instagram',
        url: 'https://www.instagram.com/dr_keat/',
      },
    ],
  },
  {
    id: '107',
    index: 107,
    date: '2023-02-14',
    name: 'What\'s it like to research the world using the Synchrotron?',
    icon: <FaLightbulb />,
    image: '/episodeImage/EleanorSynchrotron.JPG',
    synopsis: 'Today is a wonderful chat about work at the Australian Synchrotron with Dr Eleanor. We talk about the applications of a synchrotron, building a new beamline and Eleanor shares some wonderful facts about science history! We also discuss the joys of everything coming together in a career, and how science isn\'t as sioloed as it seems at school.⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Eleanor_SciDonut.mp3',
    alt: 'Cartoon of a person standing next to a cartoon synchrotron',
    guest: {
      name: 'Dr Eleanor Campbell',
      link: 'https://twitter.com/Elamanor',
      linkText: 'Follow Eleanor on Twitter',
      description: 'X-ray wrangler',
    },
    linkList: [
      {
        title: 'Check out Eleanor\'s website for more about her work',
        url: 'https://eccrystals.neocities.org/',
      },
    ],
  },
  {
    id: '108',
    index: 108,
    date: '2023-02-21',
    name: 'What\'s it like to do Forensic Chemistry?',
    icon: <FaLightbulb />,
    image: '/episodeImage/Georgina_Forensics.JPG',
    synopsis: 'This is a great chat with Dr Georgina about her work as a Forensic Chemist. We talk about some of the work she does at the moment, researching lipstick and old fingerprints. She also dispels some myths about what it\'s like to work in Forensics⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Georgina_Forensics.mp3',
    alt: 'Cartoon of a person holding a laptop and a magnifying glass and with a thought bubble and a lamp',
    guest: {
      name: 'Dr Georgina Sauzier',
      link: 'https://twitter.com/georginasauzier?lang=en',
      linkText: 'Follow Georgina on Twitter',
      description: 'Forensic Chemist',
    },
    linkList: [
      {
        title: 'Check out Georgina\'s profile for more about her work',
        url: 'https://staffportal.curtin.edu.au/staff/profile/view/georgina-sauzier-27b987ec/',
      },
    ],
  },
  {
    id: '109',
    index: 109,
    date: '2023-03-08',
    name: 'What\'s it like to research penguins?',
    icon: <FaLightbulb />,
    image: '/episodeImage/PaulaPenguins.JPG',
    synopsis: 'This was a wonderful chat with Paula about her career as a Penguin researcher. We covered a lot of ground, discussed the importance of not holding back, and learned a lot about penguins! Including the importance of respecting them as apex predators.⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Paula_Penguins.mp3',
    alt: 'Cartoon of a person holding a clipboard and wearing a hat standing next to three little penguins',
    guest: {
      name: 'Paula Wasiak',
      link: 'https://twitter.com/PaulaWasiak',
      linkText: 'Follow Paula on Twitter',
      description: 'Penguin field biologist',
    },
    linkList: [
      {
        title: 'For more about Phillip Island Nature Parks check out their webiste',
        url: 'https://staffportal.curtin.edu.au/staff/profile/view/georgina-sauzier-27b987ec/',
      },
    ],
  },
  {
    id: '110',
    index: 110,
    date: '2023-03-23',
    name: 'What\'s it like working for Double Helix Magazine?',
    icon: <FaLightbulb />,
    image: '/episodeImage/JasmineHelix.JPG',
    synopsis: 'Jasmine read Double Helix as a child and has now been working with the magazine for years! We talk about the potential future for Double Helix, AI in publishing and diversifying your skillset to help find good roles in science and communication.⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Jasmine_Helix.mp3',
    alt: 'Cartoon of a person thinking with a lightbulb and holding a double helix magazine',
    guest: {
      name: 'Jasmine Fellows',
      link: 'https://twitter.com/jasfellows',
      linkText: 'Follow Jasmine on Twitter',
      description: 'Business Development at CSIRO',
    },
    linkList: [
      {
        title: 'You can also follow Jasmine on Instagram',
        url: 'https://www.instagram.com/jasmine.fellows/',
      },
      {
        title: 'For everything Double Helix head here',
        url: 'http://doublehelix.csiro.au/',
      },
      {
        title: 'Sign up to the Double Helix mailing list here',
        url: 'https://doublehelixshop.csiro.au/eNewsletter',
      },
      {
        title: 'Learn more about the Sustainability Squad!',
        url: 'https://blog.doublehelix.csiro.au/everyday-sustainability-hero/',
      },
      {
        title: 'Check out the Melbourne Plant Project',
        url: 'https://melbourneplantproject.org/',
      },
    ],
  },
  {
    id: '111',
    index: 111,
    date: '2023-04-04',
    name: 'What\'s it like to be a facade consultant? 2',
    icon: <FaLightbulb />,
    image: '/episodeImage/lizette_facades2.JPG',
    synopsis: 'This was a great second chat with Lizette about her work as a facade consultant. We discuss some of the expected and unexpected things a facade consultant does, and what Lizette has been up to in the last two years. We also talk about what Lizette is doing next, and the events that inspired the change!⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Lizette_Facades_2.mp3',
    alt: 'Cartoon of a person holding a clipboard and standing next to a building',
    guest: {
      name: 'Lizette McNeill',
      link: 'https://www.linkedin.com/in/lizette-mcneill/',
      linkText: 'Connect with Lizette on LinkedIn',
      description: 'Principal Facade Consultant',
    },
    linkList: [
      {
        title: 'For more Lizettes endeavours check out her website  ',
        url: 'https://ixopartners.com/',
      },
    ],
  },
  {
    id: '112',
    index: 112,
    date: '2023-04-19',
    name: 'What\'s it like to research breastfeeding?',
    icon: <FaLightbulb />,
    image: '/episodeImage/AlexBreastfeeding.JPG',
    synopsis: 'This was a great chat with Dr. Alexandra who about her research on the benefits of breastfeeding for infant health, highlighting the limited understanding of human milk. She emphasizes the importance of breast milk in providing essential nutrients and unique lipids. Dr. Alexandra discusses the challenges that parents face in breastfeeding and shares some advice for young people interested in becoming scientists. As a researcher, she is passionate about making the healthcare system more evidence-based and improving support for breastfeeding parents.⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Alex_Breastfeeding.mp3',
    alt: 'Cartoon of a person holding a clipboard and thinking about milk bottles and breasts',
    guest: {
      name: 'Dr. Alexandra George',
      link: 'https://twitter.com/ArachidonicAlex',
      linkText: 'Follow Dr Alex on Twitter',
      description: 'Postdoctoral Researcher',
    },
    linkList: [
      {
        title: 'Check out Dr Alex\'s research page',
        url: 'https://baker.edu.au/research/staff/alexandra-george',
      },
      {
        title: 'If you\'d like to know more about Ultrasound imaging of the lactating breast: methodology and application',
        url: 'https://internationalbreastfeedingjournal.biomedcentral.com/articles/10.1186/1746-4358-4-4',
      },
    ],
  },
  {
    id: '113',
    index: 113,
    date: '2023-05-03',
    name: 'What\'s it like to research Octopus?',
    icon: <FaLightbulb />,
    image: '/episodeImage/EricaOcto.JPG',
    synopsis: 'In this podcast episode, we sit down with Erica, a cephalopod researcher who delves into the intricacies of these fascinating creatures. We explore the mysteries surrounding the octopus, including the difficulties in determining their age and the methods Erica uses to feed them. Beyond her work, Erica also shares her personal journey and the challenges she\'s faced in navigating the uncertain world of post-PhD careers. Join us for a captivating conversation that sheds light on the lesser-known aspects of cephalopod research and the dedication it takes to pursue a career in science.⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Erica_Octo.mp3',
    alt: 'Cartoon of a person standing next to a cartoon Octopus',
    guest: {
      name: 'Erica Donlon Durante',
      link: 'https://twitter.com/DonlonErica',
      linkText: 'Follow Erica on Twitter',
      description: 'Marine Science Researcher',
    },
    linkList: [
      {
        title: 'You can also follow Erica on Instagram',
        url: 'https://www.instagram.com/erica_donlon_durante/',
      },
    ],
  },
  {
    id: '114',
    index: 114,
    date: '2023-05-03',
    name: 'What\'s it like to be a Calm Pet Vet?',
    icon: <FaLightbulb />,
    image: '/episodeImage/ChannyVet2.JPG',
    synopsis: 'This was a lovely second chat with Dr Channy about her work as a calm pet vet. We talked about the process of starting her business, how she has approached the huge to do list and finding systems that work. Channy also shares insights into some pet behaviours and the importance of respecting our pets as concious beings who need to give constent to interactions just like us!⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_Channy_Vet.mp3',
    alt: 'Cartoon of a person standing next to a cartoon cat with a heart',
    guest: {
      name: 'Dr Chantelle McGowan',
      link: 'https://www.instagram.com/dr_channy/',
      linkText: 'Follow Dr Channy on Instagram',
      description: 'Calm Pet Vet',
    },
    linkList: [
      {
        title: 'For more about Calm Pet Vet follow them on Instagram',
        url: 'https://www.instagram.com/calmpetvet/',
      },
    ],
  },
  {
    id: '115',
    index: 115,
    date: '2023-05-31',
    name: 'What\'s it like to enable philanthropy?',
    icon: <FaLightbulb />,
    image: '/episodeImage/SarahPhilanthro.JPG',
    synopsis: 'This was a great chat with Sarah from FRRR about her fascinating journey from a degree in Law and Engineering all the way to helping enable philanthropy. We talked about different forms of philanthropy and Sarah shared some wonderful advice for her past self!⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Sarah_Philanthro.mp3',
    alt: 'Cartoon of a person thinking about the distribution of money over Australia',
    guest: {
      name: 'Sarah Matthee',
      link: 'https://www.linkedin.com/in/sarah-matthee-00299039/',
      linkText: 'Follow Sarah on LinkedIn',
      description: 'GM Partnerships & Services',
    },
    linkList: [
      {
        title: 'For more about FRRR check out their website',
        url: 'https://frrr.org.au/',
      },
    ],
  },
  {
    id: '116',
    index: 116,
    date: '2023-06-13',
    name: 'What\'s it like to research Mitochondria?',
    icon: <FaLightbulb />,
    image: '/episodeImage/TahneeMitochondria.JPG',
    synopsis: 'Dr Tahnee is trying to help us understand what we need to do to keep our Mitochondria happy, and hopefully in the process decrease the risk of certain diseases. She also shared her career story, from wanting to be a doctor, meeting her first cadaver and connecting with it as a human too quickly, to the search to find what she might be able to do other than be a medical doctor. She also shared some great advice for finding career opportunities, talk to people!⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Tahnee_WEHI.mp3',
    alt: 'Cartoon of a person standing next to a microscope with a giant cartoon mitochondria',
    guest: {
      name: 'Dr Tahnee Saunders',
      link: 'https://twitter.com/tahneesaunders',
      linkText: 'Follow Tahnee on Twitter',
      description: 'Postdoc',
    },
    linkList: [
      {
        title: 'Check out Tahnee\'s website',
        url: 'http://tahneesaunders.com/',
      },
    ],
  },
  {
    id: '117',
    index: 117,
    date: '2023-06-13',
    name: 'What\'s it like to research plant-insect interactions?',
    icon: <FaLightbulb />,
    image: '/episodeImage/LauraPlants.JPG',
    synopsis: 'Laura is doing some fascinating research, looking into how plants and insects interact, specifically how different insects use different techniques to pollinate plants. It gets a little bit weird, but I can almost guarantee you\'ll learn something! Laura is doing some amazing research, and has a fascinating career path.⁠',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Laura_Plants.mp3',
    alt: 'Cartoon of a person standing next to flower and a bee holding a clipboard and magnifying glass',
    guest: {
      name: 'Laura Lopresti',
      link: 'https://twitter.com/laura_loprestii',
      linkText: 'Follow Laura on Twitter',
      description: 'PhD Researcher',
    },
    linkList: [
      {
        title: 'For all about the Wheen Bee foundation check out their website',
        url: 'https://www.wheenbeefoundation.org.au/?',
      },
    ],
  },
  {
    id: '118',
    index: 118,
    date: '2023-07-02',
    name: 'What is special about the Spider Crabs in Port Phillip Bay?',
    icon: <FaLightbulb />,
    image: '/episodeImage/ElodieSpiderCrabs.JPG',
    synopsis: 'In this episode I talk with the Dr Elodie⁠ about her research into Spider Crabs. We talk about what we do and don\'t know about the crabs, discuss why they might gather in such large numbers and why it can be challenging to do research on marine creatures, even when they are close to shore!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Elodie_Crabs.mp3',
    alt: 'Cartoon of a person swimming with snorkelling gear and a clipboard looking at a small pile of crabs',
    guest: {
      name: 'Dr Elodie Camprasse',
      link: 'https://www.linkedin.com/in/elodie-camprasse/',
      linkText: 'Follow Elodie on LinkedIn',
      description: 'Postdoctoral Research Fellow',
    },
    linkList: [
      {
        title: 'Check out iNaturalist!',
        url: 'https://inaturalist.ala.org.au/',
      },
      {
        title: 'Help out with the spider crab research over on zooniverse',
        url: 'https://www.zooniverse.org/',
      },
    ],
  },
  {
    id: '119',
    index: 119,
    date: '2023-08-16',
    name: 'What is it like to be diagnosed with Type 1 Diabetes as an Adult?',
    icon: <FaLightbulb />,
    image: '/episodeImage/T1d.JPG',
    synopsis: 'It has been quite a long few weeks, and while I am all here to celebrate Avid Research\'s 3rd birthday, I would also like to share the adventure of being diagnosed with Type 1 Diabetes as an adult!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2022_T1d.mp3',
    alt: 'Cartoon of a blood glucose monitor and novo rapid insulin pen',
    guest: undefined,
    linkList: [
      {
        title: 'If you want to learn more about Diabetes in general, check out Diabetes Australia',
        url: 'https://www.diabetesaustralia.com.au/',
      },
    ],
  },
  {
    id: '120',
    index: 120,
    date: '2023-09-07',
    name: 'What is it like to research Evolutionary biology?',
    icon: <FaLightbulb />,
    image: '/episodeImage/EllieBirds.JPG',
    synopsis: 'This was a wonderful chat with Dr Ellie about her work researching trait evolution and speciation of birds! We discussed some of the factors that don\'t influence speciation, what a species is, and the joys of combining art and science!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Ellie_Birds.mp3',
    alt: 'Cartoon of a person with a laptop thinking about birds',
    guest: {
      name: 'Dr Eleanor Hay',
      link: 'https://www.instagram.com/eleanorhay_art/?hl=en',
      linkText: 'Follow Eleanor on Instagram',
      description: 'Postdoctoral Research Fellow',
    },
    linkList: [
      {
        title: 'For more about Dr Eleanor check out her website',
        url: 'https://eleanorhay.com/',
      },
    ],
  },
  {
    id: '121',
    index: 121,
    date: '2023-09-21',
    name: 'What is it like to help develop insect protein based pet food?',
    icon: <FaLightbulb />,
    image: '/episodeImage/NickyPetgood.JPG',
    synopsis: 'In this chat with Dr Nicky we talk about the benefits of insect based protein for our pets, visiting an insect farm and some of the exciting inititaves that are happening in this area. We also talk about trying out new roles, and creating your own opportunities!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Nicky_Petgood.mp3',
    alt: 'Cartoon of a person holding a bowl of petfood near a cat',
    guest: {
      name: 'Dr Nicky Sluczanowski',
      link: 'https://www.linkedin.com/in/dr-nicky-sluczanowski-2b6ba6135/',
      linkText: 'Follow Dr Nicky on LinkedIn',
      description: 'Lead Veterinarian ',
    },
    linkList: [
      {
        title: 'For more about Petgood check out their website',
        url: 'https://petgood.com.au/',
      },
    ],
  },
  {
    id: '122',
    index: 122,
    date: '2023-10-04',
    name: 'What is it like to research plant genetics?',
    icon: <FaLightbulb />,
    image: '/episodeImage/CaitlinPlants.JPG',
    synopsis: 'This is a fascinating and inspiring discussion with Caitlin about her research into mung bean. We talk about things we don\'t know about mung bean, and Caitlin shares a bunch of fun facts about plants, including that they have hormones. Caitlin also shares some wonderful career advice.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_CaitlinPlants.mp3',
    alt: 'Cartoon of a person with a clipboard next to some flowers',
    guest: {
      name: 'Caitlin Dudley',
      link: 'https://twitter.com/caitlindudleyy',
      linkText: 'Follow Caitlin on Twitter',
      description: 'Plant Genetics',
    },
    linkList: [
      {
        title: 'For more about the ARC Centre of Excellence for Plant Success in Nature and Agriculture',
        url: 'https://www.plantsuccess.org/',
      },
    ],
  },
  {
    id: '123',
    index: 123,
    date: '2023-11-01',
    name: 'What is it like to work as a paleontologist?',
    icon: <FaLightbulb />,
    image: '/episodeImage/HeidiPaleo.JPG',
    synopsis: 'In this episode we discuss Heidi\'s experiences in palaeontology and specifically her work with stromatolites. They talk about the role of stromatolites in understanding Earth\'s history, the process of studying and identifying stromatolites, their significance in geological mapping, and the challenges and threats they face in the modern environment. They also touch on misconceptions about stromatolites and the importance of engaging with younger generations in STEM fields.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Heidi_Paleo.mp3',
    alt: 'Cartoon of a person holding a clipboard looking at rocks',
    guest: {
      name: 'Heidi Allen',
      link: 'https://www.linkedin.com/in/heidi-allen-89021593/',
      linkText: 'Follow Heidi on LinkedIn',
      description: 'Palaeontologist',
    },
    linkList: [
      {
        title: 'Learn more about the Geological Survey of Western Australia',
        url: 'https://www.dmp.wa.gov.au/Geological-Survey/Geological-Survey-262.aspx',
      },
      {
        title: 'The curious minds STEM coaching program',
        url: 'https://curiousminds.edu.au/',
      },
    ],
  },
  {
    id: '124',
    index: 124,
    date: '2023-11-08',
    name: 'What is life like with Type 1 Diabetes? Part 2',
    icon: <FaLightbulb />,
    image: '/episodeImage/T1d.JPG',
    synopsis: 'In this follow up I chat about some of the fun facts I\'ve learned in the last couple of months since being diagnosed with Type 1 diabetes. I also share some of the challenges, misconceptions and appreciation for some wonderful medical professionals!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2021_Diabetes2.mp3',
    alt: 'Cartoon of a blood glucose monitor and novo rapid insulin pen',
    guest: undefined,
    linkList: [
      {
        title: 'For more about diabetes check out the Diabetes Australia website',
        url: 'https://www.diabetesaustralia.com.au/unite-for-change/?',
      },
    ],
  },
  {
    id: '125',
    index: 125,
    date: '2023-11-12',
    name: 'What is life like to support someone with Type 1 Diabetes?',
    icon: <FaLightbulb />,
    image: '/episodeImage/DavidDiabetes.JPG',
    synopsis: 'In this episode I interview David, my partner, about what our life change has been like with my Type 1 Diabetes diagnisis. Our discussion touches on managing blood sugar levels, the impact of diabetes on daily life, and the role of medical professionals in making the journey easier. In addition, we discuss the intricacies between high and low GI foods, carb counting, and the impact of stress on diabetes management. The episode also discusses the amount of innovation in diabetic tech, with David expressing his tech-oriented perspective on the issue.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_David_Diabetes.mp3',
    alt: 'cartoon of a concerned looking person standing next to another person in a hospital bed',
    guest: {
      name: 'David Li',
      link: 'https://www.instagram.com/misterdavidsan/',
      linkText: 'Follow David on Instagram',
      description: 'Developer',
    },
    linkList: [
      {
        title: 'If you\'d like a sneek peek of our carb counting work have a look here',
        url: 'https://carbie.vercel.app/',
      },
    ],
  },
  {
    id: '126',
    index: 126,
    date: '2023-11-22',
    name: 'What is special about the Australian Dry Lands?',
    icon: <FaLightbulb />,
    image: '/episodeImage/gresleyDrylands.JPG',
    synopsis: 'In this episode we talk about the importance of drylands and dryland rivers. Dr Gresley shares her personal experiences, some great stories and insights. The discussion fortifies the fact that drylands are a vital part of Australia\'s ecosystem and biodiversity, and that they require a better understanding and thoughtful management practices. We delve into the dynamic nature of these drylands, the life they support, and how human activities can impact these lands! Tune in for a lovely chat!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Gresley_Drylands.mp3',
    alt: 'cartoon of a person in a hat standing next to a drylands river',
    guest: {
      name: 'Dr Gresley Wakelin-King',
      link: 'https://twitter.com/Dr_Gres',
      linkText: 'Follow Gresley on Twitter',
      description: 'Geomorphologist',
    },
    linkList: [
      {
        title: 'For more about Gresley\'s work check out her website',
        url: 'https://www.wakelinassociates.com.au/',
      },
    ],
  },
  {
    id: '127',
    index: 127,
    date: '2023-12-06',
    name: 'What is it like to work in textiles and sustainability?',
    icon: <FaLightbulb />,
    image: '/episodeImage/AleashaTextiles.JPG',
    synopsis: 'This is a great chat with Aleasha the Strategic Projects manager at Monash Sustainable Development Institute about her work on transitioning to a circular economy in Australia\'s fashion industry. We talk about circular business models, the textiles project Aleasha has been working on, and discuss the impacts of the fashion industry on the environment. We talk about pathways to circular textiles in Australia, including reducing overall consumption of resources and banning the destruction of finished fashion goods. Aleasha\'s own career journey shows her dynamic progression from fashion student to her involvement in sustainability and textiles research!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Alaesha_textiles.mp3',
    alt: 'Cartoon of a person thinking about closed loop textiles',
    guest: {
      name: 'Aleasha McCallion',
      link: 'https://www.linkedin.com/in/aleashamccallion/',
      linkText: 'Follow Aleasha on LinkedIn',
      description: 'Strategic Projects Manager',
    },
    linkList: [
      {
        title: 'To learn more about the Monash Sustainability Institute check out their website',
        url: 'https://www.monash.edu/msdi',
      },
      {
        title: 'Take a read of the textiles transition report',
        url: 'https://www.monash.edu/msdi/initiatives/reports/textiles-a-transitions-report-for-australia',
      },
    ],
  },
  {
    id: '128',
    index: 128,
    date: '2023-12-13',
    name: 'What is it like to be the COO of FLEET?',
    icon: <FaLightbulb />,
    image: '/episodeImage/TichLamFleet.JPG',
    synopsis: 'This is a great chat with Dr Tich-Lam the COO of FLEET. We talk about being involved in setting up a center of excellence and the exciting work that\'s happening in low energy electronics. Tich-Lam shares her curvey career journey along with some lovely advice for her past self. Tune in for a great chat and to learn about what it takes to be a COO.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2023_Tich+Lam_FLEET.mp3',
    alt: 'Cartoon of a person thinking about people and ideas',
    guest: {
      name: 'Dr Tich-Lam Nguyen',
      link: 'https://www.linkedin.com/in/tichlamnguyen/',
      linkText: 'Follow Tich-Lam on LinkedIn',
      description: 'Chief Operating Officer',
    },
    linkList: [
      {
        title: 'To learn more about FLEET check out their website',
        url: 'https://www.fleet.org.au/',
      },
    ],
  },
  {
    id: '129',
    index: 129,
    date: '2024-03-21',
    name: 'Avid Research in 2024',
    icon: <FaLightbulb />,
    image: '/episodeImage/2024Welcome.JPG',
    synopsis: 'Returning after a summer break with updates on Avid Research\'s plan for 2024, including interviews with passionate individuals about their research, career paths, and more. We invites past and new guests to share their stories and discusses an upcoming project on career maps, aiming to help others construct and share their own career journeys. Amelia also introduces a personal update on living with Type 1 Diabetes, sharing insights into the steep learning curve and the technology she uses to manage it, including a continuous glucose monitor and an insulin pump.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/Welcome_back_2024.mp3',
    alt: 'Cartoon of the number 2024!',
    guest: undefined,
    linkList: [
      {
        title: 'For a refresher on the career maps head over here',
        url: 'https://avidresearch.com.au/maps/',
      },
    ],
  },
  {
    id: '130',
    index: 130,
    date: '2024-05-02',
    name: 'What\'s it like to be a Principal Geologist?',
    icon: <FaLightbulb />,
    image: '/episodeImage/AnnaGeol.JPG',
    synopsis: 'Listen to this episode for a fascinating exploration of South Australia\'s geological wonders with Dr. Anna, a Principal Geologist. Discover the integration of mineral and energy resource studies, evolution of geological mapping techniques, and the unique geological features of Australia. We talk about everything from the Tanami to  career advice for aspiring geologists and the future of sustainable mining practices.',
    path: 'https://op3.dev/e/avidresearchepisodes.s3.amazonaws.com/2024_Anna_Geol.mp3',
    alt: 'Cartoon of a person in a hard hat holding a clipboard and a map of south australia',
    guest: {
      name: 'Dr Anna Petts',
      link: 'https://www.linkedin.com/in/anna-petts-71400720/',
      linkText: 'Follow Anna on LinkedIn',
      description: 'Program Coordinator',
    },
    linkList: [
      {
        title: 'Learn more about the Department for Energy and Mining',
        url: 'https://www.energymining.sa.gov.au/',
      },
    ],
  },
  {
    id: '131',
    index: 131,
    date: '2024-05-29',
    name: 'What\'s it like to be inspired by Antarctica?',
    icon: <FaLightbulb />,
    image: '/episodeImage/JarrodAntarctica.JPG',
    synopsis: 'In this episode we welcome back Dr. Jarrod, who shares his recent experiences from a trip to Antarctica. The episode dives into the ecological significance of the continent, discussing the impacts of climate change on its unique wildlife and environments. Dr. Jarrod highlights the challenges faced by the fragile ecosystem, the surprising extent of Antarctic tourism, the importance of conservation efforts, and the urgent need for increased environmental awareness and action.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2024_Jarrod_Antarctica.mp3',
    alt: 'Cartoon of a person standing on a ship at sea',
    guest: {
      name: 'Dr Jarrod McKenna',
      link: 'https://www.instagram.com/its_drmac/',
      linkText: 'Follow Jarrod on Instagram',
      description: 'Science Communicator',
    },
    linkList: [
      {
        title: 'For everything Australia and Antarctica head over to the AAD',
        url: 'https://www.antarctica.gov.au/',
      },
    ],
  },
  {
    id: '132',
    index: 132,
    date: '2024-07-04',
    name: 'What\'s it like to be the COO of RadInnovate?',
    icon: <FaLightbulb />,
    image: '/episodeImage/IngridCOO.JPG',
    synopsis: 'In this episode we have a wonderful interview with Ingrid, Chief Operating Officer of RAD Innovate, funded by the Australian Research Council. Ingrid discusses her role, which involves managing the center, liaising with industry partners, setting up the website and social media, managing finances, and organizing student training and community engagement. Ingrid explains how RAD Innovate collaborates with  universities and industry partners ranging from mining organisations to medical startups. The center focuses on radiation and nuclear science, providing industry experience to PhD and master\'s students. Ingrid also touches on her varied career journey, which includes roles in water resources, citizen science, retail, teaching, and event management. She emphasizes the importance of flexibility and continual learning, noting that even non-linear career paths provide valuable skills.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2024_Ingrid_COO+RadInnovate.mp3',
    alt: 'Cartoon of a person holding a laptop and thinking about ideas',
    guest: {
      name: 'Ingrid McCarthy',
      link: 'https://www.linkedin.com/in/ingrid-mccarthy-0707/',
      linkText: 'Follow Ingrid on LinkedIn',
      description: 'COO',
    },
    linkList: [
      {
        title: 'For more about RadInnovate click here',
        url: 'https://www.anu.edu.au/research/research-innovation-news/new-arc-training-centre-in-radiation-innovation',
      },
    ],
  },
  {
    id: '133',
    index: 133,
    date: '2024-08-01',
    name: 'What\'s it like to research Diabetes?',
    icon: <FaLightbulb />,
    image: '/episodeImage/NealeDiabetesBaker.JPG',
    synopsis: 'In this episode I had a fascinating chat with Associate Professor Dr Neale Cohen about his work at The Baker as an Endocrinologist and researcher. We talk about the ins and outs of different types of diabetes, including if animals can get diabetes! Neale also shares some of the exciting research that is being one on Artificial Pancreas systems for Type 1 Diabetes. Tune in for a whole lot of fun diabetes facts.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/DrNeale_Diabetes.mp3',
    alt: 'cartoon of a person holding a clipboard',
    guest: {
      name: 'Associate Professor Dr Neale Cohen',
      link: 'https://www.linkedin.com/in/neale-cohen-0aa21436/',
      linkText: 'Follow Neale on LinkedIn',
      description: 'Associate Professor',
    },
    linkList: [
      {
        title: 'For more about the Baker click here',
        url: 'https://baker.edu.au/',
      },
    ],
  },
  {
    id: '134',
    index: 134,
    date: '2025-04-10',
    name: 'What\'s it like to study Human Nutrition?',
    icon: <FaLightbulb />,
    image: '/episodeImage/.JPG',
    synopsis: '',
    path: '',
    alt: '',
    guest: {
      name: 'Prof Emerita Jennie Brand-Miller',
      link: 'https://www.sydney.edu.au/science/about/our-people/academic-staff/jennie-brandmiller.html',
      linkText: 'Read Dr Jeannies page here',
      description: 'Prof Emerita',
    },
    linkList: [
      {
        title: 'For more about GI click here',
        url: 'https://glycemicindex.com/',
      },
    ],
  },
  {
    id: '135',
    index: 135,
    date: '2024-08-13',
    name: 'What\'s it like to research Plant and Insect Interactions? Part 2',
    icon: <FaLightbulb />,
    image: '/episodeImage/Laura2024WebsiteCard.JPG',
    synopsis: 'In this episode we welcome back Laura, a researcher investigating the interactions between plants and insects by focusing on the invasive species Senna obtusifolia, Sicklepod. Laura discusses various ecological hypotheses, such as the enemy release hypothesis and the missed mutualist hypothesis, and shares her fieldwork experiences in both Australia and Mexico. She explains her methods for studying pollination, including capturing insects and analyzing pollen grains. Laura also dives into the logistical challenges of her research, including the complexities of transporting insect and plant samples internationally. Currently, she is conducting greenhouse experiments to study plant self-compatibility and drought stress. Tune in for a great followup chat!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2024_Laura_Followup.mp3',
    alt: 'Cartoon of a person holding a flower with a bee hovering',
    guest: {
      name: 'Laura Lopresti',
      link: 'https://www.linkedin.com/in/laura-lopresti-931452231/',
      linkText: 'Follow Laura on LinkedIn',
      description: 'Ecologist',
    },
    linkList: [
      {
        title: 'For all about the Wheen Bee foundation check out their website',
        url: 'https://www.wheenbeefoundation.org.au/?',
      },
    ],
  },
  {
    id: '136',
    index: 136,
    date: '2024-09-05',
    name: 'What\'s it like to research Bees and Bee Hotels?',
    icon: <FaLightbulb />,
    image: '/episodeImage/HollyBees.JPG',
    synopsis: 'This is a great chat with Holly who is doing her PhD on the potential benefits and risks of using bee hotels! We talk about everything from understanding what bees need in a home to parasitic beetles!',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2024_Holly_Bees.mp3',
    alt: 'Cartoon of a person in a hat with a camera and clipboard looking at a bee and a bee hotel',
    guest: {
      name: 'Holly Farnan',
      link: 'https://www.instagram.com/holly_farnan_entomologist_/?img_index=1',
      linkText: 'Follow Holly on Instagram',
      description: 'Bee Ecologist',
    },
    linkList: [
      {
        title: 'Check out Holly\'s paper!',
        url: 'https://royalsocietypublishing.org/doi/10.1098/rsos.230949',
      },
    ],
  },
  {
    id: '137',
    index: 137,
    date: '2024-09-05',
    name: 'What\'s it like teach SciComm and research climate?',
    icon: <FaLightbulb />,
    image: '/episodeImage/Linden2.JPG',
    synopsis: 'In this interview Dr Linden shares some wonderful insights into both Science Communication and Climate Science. She shares her experiences helping students to communicate their science and some of the challenges and opportunities that come with SciComm. Dr Linden also gives some great insights into her work investigating climate history in Australia.',
    path: 'https://avidresearchepisodes.s3.amazonaws.com/2024_LindenSciComm.mp3',
    alt: 'Cartoon of a person holding an old notebook and a laptop with a graph on it',
    guest: {
      name: 'Dr Linden Ashcroft',
      link: 'https://www.linkedin.com/in/linden-ashcroft-08640b59/',
      linkText: 'Follow Linden on LinkedIn',
      description: 'Climate Scientist and Science Communicator',
    },
    linkList: [
      {
        title: 'Check out the Melbourne Uni SciComm hub!',
        url: 'https://blogs.unimelb.edu.au/science-communication/',
      },
    ],
  },
  {
    id: '138',
    index: 138,
    date: '2024-10-30',
    name: 'What\'s it like to be a green careers coach?',
    icon: <FaLightbulb />,
    image: '/episodeImage/Anna_Careers.JPG',
    synopsis: 'In this episode I chat with Anna Sidoti, a green career coach. We delve into the importance of green jobs, the diversity of opportunities within the field, and how Anna provides guidance to individuals seeking environmentally sustainable careers. Anna also discusses her journey into career coaching, the significance of scientific literacy, career management, and the evolving landscape of green careers. Our conversation highlights the necessity of diverse perspectives and the potential for hope and innovation in the green sector.',
    path: 'https://avidresearchepisodes.s3.us-east-1.amazonaws.com/2024_Anna_Careers.mp3',
    alt: 'Cartoon of a person with an idea bubble and a speech bubble with a question mark',
    guest: {
      name: 'Anna Sidoti',
      link: 'https://www.linkedin.com/in/annasidoti25/',
      linkText: 'Follow Anna on LinkedIn',
      description: 'Green Career Coach',
    },
    linkList: [
      {
        title: 'Check out Annas website!',
        url: 'https://www.evergreencareers.net/',
      },
    ],
  },
  {
    id: '139',
    index: 139,
    date: '2024-11-14',
    name: 'What\'s it like to work as a Science informed Artist?',
    icon: <FaLightbulb />,
    image: '/episodeImage/LucyArts.JPG',
    synopsis: 'This is a wonderful chat with Lucy, an artist who uniquely combines her background in science with her passion for painting. Lucy shares insights into her creative process, including making her own paints from natural materials such as soil, earth, and plants, and discusses how her scientific training aids her artistic endeavors. We chat about the technical aspects of paint making, the significance of the environment in her art, and the emotional and professional journey she has navigated from being a research assistant to a full-time artist. Lucy also touches on the misconceptions about art and science, the importance of creativity in both fields, and offers advice for aspiring artists.',
    path: 'https://avidresearchepisodes.s3.us-east-1.amazonaws.com/2024_Lucy_Arts.mp3',
    alt: 'Cartoon of a person with a paint brush and an easle looking at a rolling hill landscape',
    guest: {
      name: 'Lucy Hersey',
      link: 'https://www.instagram.com/lucehersey/',
      linkText: 'Follow Lucy on Instagram',
      description: 'Artist',
    },
    linkList: [
      {
        title: 'Check out Lucy\'s website!',
        url: 'https://www.lucyhersey.com/',
      },
    ],
  },
  {
    id: '140',
    index: 140,
    date: '2025-11-14',
    name: 'What is it like to work at the intersection of Science and Business?',
    icon: <FaLightbulb />,
    image: '/episodeImage/PallaveSciBus.JPG',
    synopsis: '',
    path: 'https://avidresearchepisodes.s3.us-east-1.amazonaws.com/2024_Anna_Careers.mp3',
    alt: 'Cartoon of a person with a laptop and an idea light bulb',
    guest: {
      name: 'Pallave Dasari',
      link: 'https://www.linkedin.com/in/pallave-dasari-87369989/?',
      linkText: 'Follow Pallave on LinkedIn',
      description: 'Business Development Manager',
    },
    linkList: [
      {
        title: 'Learn more about Pallave\'s research here',
        url: 'https://researchers.adelaide.edu.au/profile/pallave.dasari',
      },
    ],
  },
  {
    id: '141',
    index: 141,
    date: '2025-11-28',
    name: 'What is it like to be a Diabetes Educator?',
    icon: <FaLightbulb />,
    image: '/episodeImage/SteveCDE.JPG',
    synopsis: '',
    path: 'https://avidresearchepisodes.s3.us-east-1.amazonaws.com/2024_Anna_Careers.mp3',
    alt: 'Cartoon of a person with a clipboard, cake a table an insulin pen and a blood glucose monitor',
    guest: {
      name: 'Anna Sidoti',
      link: 'https://www.linkedin.com/in/annasidoti25/',
      linkText: 'Follow Anna on LinkedIn',
      description: 'Green Career Coach',
    },
    linkList: [
      {
        title: 'Check out Annas website!',
        url: 'https://www.evergreencareers.net/',
      },
    ],
  },
  {
    id: '142',
    index: 142,
    date: '2025-12-05',
    name: 'What is it like to move through career uncertainty?',
    icon: <FaLightbulb />,
    image: '/episodeImage/Emily_Careers.JPG',
    synopsis: '',
    path: 'https://avidresearchepisodes.s3.us-east-1.amazonaws.com/2024_Anna_Careers.mp3',
    alt: 'Cartoon of a person thinking about lots of question marks',
    guest: {
      name: 'Anna Sidoti',
      link: 'https://www.linkedin.com/in/annasidoti25/',
      linkText: 'Follow Anna on LinkedIn',
      description: 'Green Career Coach',
    },
    linkList: [
      {
        title: 'Check out Annas website!',
        url: 'https://www.evergreencareers.net/',
      },
    ],
  },
  {
    id: '143',
    index: 143,
    date: '2025-12-12',
    name: 'What is it like to be a singing Scientist?',
    icon: <FaLightbulb />,
    image: '/episodeImage/CatSinging.JPG',
    synopsis: '',
    path: 'https://avidresearchepisodes.s3.us-east-1.amazonaws.com/2024_Anna_Careers.mp3',
    alt: 'Cartoon of a person holding a ukelale with music notes',
    guest: {
      name: 'Anna Sidoti',
      link: 'https://www.linkedin.com/in/annasidoti25/',
      linkText: 'Follow Anna on LinkedIn',
      description: 'Green Career Coach',
    },
    linkList: [
      {
        title: 'Check out Annas website!',
        url: 'https://www.evergreencareers.net/',
      },
    ],
  },
];
