import React from 'react';
import podcastProviderList from '../../data/careerMaps';
import careerMapsStyles from './careerMaps.module.css';

export default function Maps() {
  return (
    <div className={careerMapsStyles.container}>
      <h1
        className={careerMapsStyles.header}
      >
        Avid Research Career Maps
      </h1>
      {
      podcastProviderList.map((map) => (
        <div
          className={careerMapsStyles.wrapper}
        >
          <h2
            className={careerMapsStyles.title}
          >
            {`${map.person}'s career map: becoming a - ${map.career}`}
          </h2>
          <img
            src={map.map}
            alt={map.alt}
            className={careerMapsStyles.image}
          />
          <a
            key={`map_${map.person}`}
            href={map.episode}
            target="_blank"
            rel="noreferrer"
            className={careerMapsStyles.link}
          >
            {`Listen to ${map.person}'s episode`}
          </a>
        </div>

      ))
    }
    </div>
  );
}
