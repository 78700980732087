import React from 'react';
import instaStyles from './insta.module.css';
import ClassicLink from '../classicLink';
import InstaLinks from '../../data/instaLinks';

export default function Insta() {
  return (
    <div className={instaStyles.container}>
      {
        InstaLinks.map((link) => (
          <ClassicLink
            href={link.href}
          >
            {link.label}
          </ClassicLink>
        ))
      }
    </div>
  );
}
