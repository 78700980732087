import React from 'react';
import Hero from '../hero';
import PodcastListing from '../podcastListing';
import headerStyles from './home.module.css';

export default function Home() {
  return (
    <div className={headerStyles.home}>
      <Hero />
      <PodcastListing />
    </div>
  );
}
