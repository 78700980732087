import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import emailSignupStyles from './emailSignup.module.css';

export default function emailSignup(props) {
  const {
    heading = 'Subscribe for episode updates!',
  } = props;
  const [email, setEmail] = useState('');
  const [submission, setSubmission] = useState({
    isSubmitted: false,
    result: undefined,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await addToMailchimp(email);
    setSubmission({
      isSubmitted: true,
      result: response.result,
    });
  };
  return (
    <div>
      <form onSubmit={(e) => (handleSubmit(e))}>
        <div className={emailSignupStyles.container}>
          <label
            htmlFor="emailInput"
            className={emailSignupStyles.label}
          >
            <h2>{heading}</h2>
            {
              submission.isSubmitted
              && submission.result === 'success'
              && <p className={emailSignupStyles.submission}>{'Thanks! We\'ll email you soon'}</p>
            }
            {
              submission.isSubmitted
              && submission.result === 'error'
              && (
                <p
                  className={emailSignupStyles.submission}
                >
                  Hmm, something went wrong. Please try again later!
                </p>
              )
            }
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="email"
              value={email}
              name="EMAIL"
              className={emailSignupStyles.emailInput}
              id="emailInput"
              placeholder="email address"
              required
            />
          </label>
          <div className={emailSignupStyles.buttonContainer}>
            <input
              className={emailSignupStyles.button}
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
