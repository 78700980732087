import React from 'react';
import {
  FaInstagram,
  FaHeart,
  FaReact,
  FaCss3Alt,
  FaAws,
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutubeSquare,
} from 'react-icons/fa';
import { GrGatsbyjs } from 'react-icons/gr';
import footerStyles from './footer.module.css';

export default () => (
  <div className={footerStyles.container}>
    <p className={footerStyles.acknowledgement}>
      Avid Research would like to acknowledge the Traditional Custodians of the lands
      on which this website was created, is hosted and is viewed.
      The podcast is created on the lands of the Wautharong
      peoples of the Kulin Nation. We pay respects to their Elders past and present,
      and acknowledge them as the original inspiring and ongoing contributors to STEM
      and storytelling in Australia.
    </p>
    <p className={footerStyles.footer}>
      Thank you for visiting Avid Research. Follow us on
      <a href="https://www.instagram.com/avid_research/?hl=en" target="_blank" rel="noreferrer">
        {' '}
        <span className={footerStyles.icons}><FaInstagram /></span>
        {' '}
      </a>
      <a href="https://www.facebook.com/AvidResearch" target="_blank" rel="noreferrer">
        {' '}
        <span className={footerStyles.icons}><FaFacebookSquare /></span>
        {' '}
      </a>
      <a href="https://twitter.com/avid_podcast" target="_blank" rel="noreferrer">
        {' '}
        <span className={footerStyles.icons}><FaTwitterSquare /></span>
        {' '}
      </a>
      <a href="https://www.youtube.com/channel/UCdPa9O8UXh9c1dG8FFVWfDQ" target="_blank" rel="noreferrer">
        {' '}
        <span className={footerStyles.icons}><FaYoutubeSquare /></span>
        {' '}
      </a>
      to get involved!
      <br />
      {'Made with '}
      <span className={footerStyles.icons}>
        <FaHeart />
        {' '}
        <FaReact />
        {' '}
        <GrGatsbyjs />
        {' '}
        <FaCss3Alt />
        {' '}
        <FaAws />
        {' '}
      </span>
      in Melbourne by
      <a href="https://www.linkedin.com/in/amelia-travers/" target="_blank" rel="noreferrer"> Amelia</a>
      <br />
      {`Copyright ${(new Date()).getFullYear()} Avid Research.`}
    </p>
  </div>
);
