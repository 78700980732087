import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import AboutContent from '../aboutContent';
import CareerMaps from '../careerMaps';
import Home from '../home';
import Insta from '../insta';
import Exhibition from '../exhibition';
import BookList from '../bookList';

export default function Template(props) {
  const {
    page,
    hasNav,
  } = props;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Avid Research - An Australian STEM Podcast</title>
        <link rel="canonical" href="https://avidresearch.com.au/" />
      </Helmet>
      <Header
        hasNav={hasNav}
      />
      {page === 'about' && <AboutContent />}
      {page === 'maps' && <CareerMaps />}
      {page === 'insta' && <Insta />}
      {page === 'exhibition' && <Exhibition />}
      {page === 'bookList' && <BookList />}
      {!page && <Home /> }
      <Footer />
    </div>
  );
}

Template.defaultProps = {
  hasNav: true,
};
