import React from 'react';
import navContent from '../../data/navContent';
import navStyles from './nav.module.css';

export default function Nav() {
  return (
    <div className={navStyles.container}>
      {
      navContent.map((nav) => (
        <div
          className={navStyles.wrapper}
        >
          <a
            key={`nav_${nav.title}`}
            href={nav.link}
            rel="noreferrer"
            className={navStyles.link}
          >
            {nav.title}
          </a>
        </div>

      ))
    }
    </div>
  );
}
